import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RadialChart = ({ topBrands, totalPrice }) => {

  const brands = topBrands && Array.isArray(topBrands) ? topBrands : [];
  const total = totalPrice || 0; 

  const series = brands.length > 0 
    ? brands.map(brand => parseFloat(((brand.totalPrice / total) * 100)?.toFixed(2)))
    : [];
  
  const labels = brands.length > 0 ? brands.map(brand => brand.brandName) : [];

  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 10,
          size: "45%",
        },
        track: {
          show: true,
          strokeWidth: "70%",
          margin: 12,
        },
        dataLabels: {
          name: {
            fontSize: "27px",
            color: '#FF5733',
          },
          value: {
            fontSize: "20px",
            color: '#C70039', 
          },
          total: {
            show: true,
            label: "Total",
            formatter: function (w) {
              return total; 
            },
          },
        },
      },
    },
    labels: labels,
    colors: ["#099680", "#63affd", "#5664d2"]
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height="350"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default RadialChart;
