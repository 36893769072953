import { ADD_NOTIFY, DELETE_NOTIFY, GET_NOTIFY, UPDATE_NOTIFY } from "../types";
import {setAlert} from "./alert";
import axios from "../../axios";
import Swal from 'sweetalert2';

export const getNotify = () => async (dispatch) => {
    try {
        const res = await axios.get("noti/get");
        if (res.data.code === 1) {
            dispatch({type: GET_NOTIFY, payload: res.data.data});
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addNotify = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("noti/add", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        if (res.data.code === 1) {
            dispatch({type: ADD_NOTIFY, payload: res.data.data});
            dispatch(setAlert(res.data.message, 'success'));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const updateNotify = (formData) => async (dispatch) => {
    try {
      const res = await axios.post("noti/update", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (res.data.code === 1) {
        dispatch({type: UPDATE_NOTIFY, payload: res.data.data});
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
        });
        dispatch(setAlert(res.data.message, 'success'));
      } else {
        dispatch(setAlert(res.data.message, "warning"));
      }
    } catch (err) {
      dispatch(setAlert(err.message, 'danger'));
    }
  };

export const deleteNotify = (_id) => async (dispatch) => {
    try {
        if (!_id) {
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.post(`noti/delete/${_id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_NOTIFY, payload: {_id}});
            dispatch(setAlert(res.data.message, 'success'));
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};





