import React, { useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../store/actions/state";
import { getCountries } from "../../store/actions/country";

const CityForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const StateList = useSelector((state) => state.state.list);
  const countryList = useSelector((state) => state.country.list);

  useEffect(() => {
    if (visible) {
      dispatch(getCountries())
      dispatch(getStates());
    }
  }, [visible, dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
  }, [record, form]);

  return (
    <Modal
      visible={visible}
      // title={record ? "Update City" : "Add City"}
      title={"Add City"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="model_form"
        initialValues={record}
      >
        <Form.Item
          name="countryId"
          label="Country"
          rules={[{ required: true, message: "Please select a country!" }]}
        >
          <Select placeholder="Select a country">
            {countryList.map((country) => (
              <Select.Option key={country._id} value={country._id}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="stateId"
          label="State"
          rules={[{ required: true, message: "Please select a state!" }]}
        >
          <Select placeholder="Select a state">
            {StateList.map((state) => (
              <Select.Option key={state._id} value={state._id}>
                {state.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the city name!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CityForm;
