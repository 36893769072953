import {  ADD_PRODUCTS, DELETE_PRODUCTS, DELETE_PRODUCTS_IMAGE, GET_DASH_PRODUCTS, GET_PRODUCTS, UPDATE_PRODUCTS } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PRODUCTS:
            return { ...state, list: payload };
        
        case GET_DASH_PRODUCTS:
            return { ...state, list: payload };

        case ADD_PRODUCTS:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case UPDATE_PRODUCTS:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case DELETE_PRODUCTS:
            return {
                ...state,
                list: state.list.filter((item) => item._id !== payload._id),
            };

        case DELETE_PRODUCTS_IMAGE:
            return {
                ...state,
                list: state.list.filter((item) => item._id !== payload._id),
            };

        default:
            return state;
    }
};

export default reducer;
