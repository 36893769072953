import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  InputNumber,
  Select,
  Row,
  Col,
  Upload,
  Switch,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { getModels } from "../../store/actions/model";
import { getBrands } from "../../store/actions/brand";
import { getSeries } from "../../store/actions/series";
import { addProducts, updateProducts } from "../../store/actions/products"; // Import your actions

const ProductsForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [imeiFields, setImeiFields] = useState([0]); // Default one IMEI field

  const dispatch = useDispatch();
  const BrandList = useSelector((state) => state.brand.list);
  const ModelList = useSelector((state) => state.model.list);
  const SeriesList = useSelector((state) => state.series.list);

  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(getModels(selectedBrand));
      // Clear selected model and series when brand changes
      setSelectedModel(null);
      form.setFieldsValue({ modelId: undefined, seriesId: undefined });
    }
  }, [selectedBrand, dispatch]);

  useEffect(() => {
    if (selectedModel) {
      dispatch(getSeries(selectedModel));
      // Clear selected series when model changes
      form.setFieldsValue({ seriesId: undefined });
    }
  }, [selectedModel, dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        // qty: record.qty || 0,
        qty: 0,
      });
      setImeiFields(
        // Array.from({ length: record.qty || 0 }, (_, index) => index)
        Array.from({ length: 0 }, (_, index) => index)
      );
      setSelectedBrand(record.brandId);
      setSelectedModel(record.modelId);
    } else {
      form.resetFields();
      setSelectedBrand(null);
      setSelectedModel(null);
    }
  }, [record, form]);

  useEffect(() => {
    // Fetch brands and models on component mount
    dispatch(getBrands());
    dispatch(getModels());
    dispatch(getSeries());
  }, [dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
      setSelectedBrand(record.brandId); // Set the initial selected brand
    } else {
      form.resetFields(); // Reset the form if there's no record
      setSelectedBrand(null); // Clear the selected brand
    }
  }, [record, form]);

  const handleBrandChange = (value) => {
    setSelectedBrand(value);
  };
  const handleModelChange = (value) => {
    setSelectedModel(value);
  };

  // Filter models based on selected brand
  const filteredModels = ModelList.filter(
    (model) => model.brandId === selectedBrand
  );

  const filteredSeries = SeriesList.filter(
    (series) => series.modelId === selectedModel
  );

  const handleQtyChange = (value) => {
    setImeiFields(Array.from({ length: value }, (_, index) => index));
  };

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpeg';
      if (!isPNG || !isJPG) {
        message.error(`${file.name} is not a png/jpeg file`);
      }
      return isPNG || isJPG || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const qty = values.qty || 1;
        const imeiArray = imeiFields.map((_, index) =>
          form.getFieldValue(`imei[${index}]`)
        );

        // Ensure that the number of IMEI fields matches the quantity
        if (imeiArray.length < qty) {
          return Promise.reject(
            new Error("Please provide enough IMEI numbers for the quantity.")
          );
        }

        const apiCalls = [];
        for (let i = 0; i < qty; i++) {
          const formData = new FormData();
          Object.keys(values).forEach((key) => {
            if (key !== "imei" && key !== "images") {
              // Exclude images here
              formData.append(key, values[key]);
              formData.append("qty", 1);
            }
          });

          // Append images
          const images = form.getFieldValue("images");
          if (images && Array.isArray(images)) {
            images.forEach((file) => {
              if (file.originFileObj) {
                formData.append("images", file.originFileObj);
              }
            });
          }

          formData.append("imei", imeiArray[i]);

          if (record) {
            // Update existing product
            formData.append("id", record._id); // Append the product ID for updating
            apiCalls.push(dispatch(updateProducts(formData)));
          } else {
            // Add new product
            apiCalls.push(dispatch(addProducts(formData)));
          }
        }

        // Execute all API calls
        Promise.all(apiCalls)
          .then(() => {
            onCreate(); // Notify parent component
            form.resetFields(); // Reset the form
            setImeiFields([0]); // Reset IMEI fields
            setSelectedBrand(null);
            setSelectedModel(null);
          })
          .catch((err) => {
            console.error("Error submitting form:", err);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Product" : "Add Product"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="product_form"
        initialValues={record}
      >
        {/* Form Fields */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: "Please enter the product name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="brandId"
              label="Brand"
              rules={[{ required: true, message: "Please select a brand!" }]}
            >
              <Select
                placeholder="Select a brand"
                onChange={handleBrandChange}
                value={selectedBrand}
              >
                {BrandList.map((brand) => (
                  <Select.Option key={brand._id} value={brand._id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="modelId"
              label="Model"
              rules={[{ required: true, message: "Please select a model!" }]}
            >
              <Select
                placeholder="Select a model"
                onChange={handleModelChange}
                value={selectedModel}
                disabled={!selectedBrand}
              >
                {filteredModels.map((model) => (
                  <Select.Option key={model._id} value={model._id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="seriesId"
              label="Series"
              rules={[{ required: true, message: "Please select a series!" }]}
            >
              <Select
                placeholder="Select a series"
                value={form.getFieldValue("seriesId")}
                disabled={!selectedModel}
              >
                {filteredSeries.map((series) => (
                  <Select.Option key={series._id} value={series._id}>
                    {series.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="qty"
              label="Quantity"
              rules={[
                { required: true, message: "Please enter the quantity!" },
              ]}
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                onChange={handleQtyChange}
                // defaultValue={record?.qty || 1}
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        {imeiFields.map((field, index) => (
          <Row gutter={16} key={field}>
            <Col span={24}>
              <Form.Item
                name={`imei[${index}]`}
                label={`IMEI ${index + 1}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter the IMEI number!",
                    max: 15,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        ))}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="color"
              label="Color"
              rules={[{ required: true, message: "Please enter the color!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="price"
              label="Price"
              rules={[{ required: true, message: "Please enter the price!" }]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="storage"
              label="Storage"
              rules={[{ required: true, message: "Please enter the storage!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="network" label="Network">
              <Select placeholder="Select a network type">
                <Select.Option value="4g">4G</Select.Option>
                <Select.Option value="5g">5G</Select.Option>
                <Select.Option value="3g">3G</Select.Option>
                <Select.Option value="lte">LTE</Select.Option>
                {/* Add other network types if needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="osNo" label="OS">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="ram" label="RAM">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="about" label="About">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="battery" label="Battery">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="guarantee" label="Guarantee">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="warranty" label="Warranty">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="category" label="Category">
              <Select placeholder="Select a Category type">
                <Select.Option value="Mobile">Mobile</Select.Option>
                <Select.Option value="Tablet">Tablet</Select.Option>
                <Select.Option value="Watch">Watch</Select.Option>
                <Select.Option value="Airpod">Airpod</Select.Option>
                <Select.Option value="Accessories">Accessories</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="usedTime" label="Used Time">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="youtubeUrl" label="YouTube URL">
              <Input defaultValue={""} placeholder="Add Youtube Link" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="images"
              label="Images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Please upload at least one image!",
                },
              ]}
            >
              <Upload {...props} listType="picture-card" accept="image/png, image/jpeg" multiple>
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductsForm;
