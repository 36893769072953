import Swal from "sweetalert2";
import axios from "../../axios";
import { ADD_BANNER, DELETE_BANNER, GET_BANNER, UPDATE_BANNER } from "../types";
import { setAlert } from "./alert";

export const addBanner = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  };
  
    const res = await axios.post("ban/add", formData, config);
    if (res.data.code === 1) {
      dispatch({ type: ADD_BANNER, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
export const getBanner = () => async (dispatch) => {
  try {
    const res = await axios.get("ban/get");
    console.log("banner :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_BANNER, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateBanner = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  };
    const updatedFormData = new FormData();
    updatedFormData.append('id', formData._id); // Ensure _id is present
    if (formData.name) updatedFormData.append('name', formData.name);
    if (formData.url) updatedFormData.append('url', formData.url);
    if (formData.reference) updatedFormData.append('reference', formData.reference);
    if (formData.image && formData.image.length > 0) {
      updatedFormData.append('image', formData.image[0].originFileObj);
    }
    
    const res = await axios.post("ban/update", updatedFormData, config);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_BANNER, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'warning!',
        text: res.data.message,
        icon: 'warning',
    });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateBannerStatus = (id, status) => async (dispatch) => {
  try {
      // const updatedFormData = { ...formData, id: formData._id };
      // console.log("formdata updateCustomerStatus:", id, status);
      
      const res = await axios.post("ban/update", {id, status}, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      
      
      if (res.data.code === 1) {
          dispatch({type: UPDATE_BANNER, payload: res.data.data});
          Swal.fire({
              title: 'Success!',
              text: res.data.message,
              icon: 'success',
          });
          dispatch(setAlert(res.data.message, 'success'));
      } else {
          dispatch(setAlert(res.data.message, "warning"));
          Swal.fire({
              title: 'Warning!',
              text: res.data.message,
              icon: 'warning',
          });
      }
  } catch (err) {
      dispatch(setAlert(err.message, 'danger'));
      const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

      Swal.fire({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
      });
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`ban/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_BANNER, payload: { id } });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
