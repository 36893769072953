import React, { useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getBrands } from '../../store/actions/brand';

const ModelsForm = ({ visible, onCreate, onCancel, record, categoryOptions }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const brandList = useSelector((state) => state.brand.list);

  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (visible) {
      if (record) {
        form.setFieldsValue({
          name: record.name,
          category: record.category,
          brandId: record.brandId,
          order: record.order ? record.order : null,
        });
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  }, [visible, record, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onCreate(values);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Series" : "Add Series"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleSubmit}
      width={800}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="model_form"
      >
        <Form.Item
          name="brandId"
          label="Brand"
          rules={[{ required: true, message: 'Please select a brand!' }]}
        >
          <Select placeholder="Select a brand">
            {brandList.map((brand) => (
              <Select.Option key={brand._id} value={brand._id}>
                {brand.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select a product category!",
            },
          ]}
        >
          <Select 
            placeholder="Select a Category type"
            showSearch
            optionFilterProp="children"
          >
            {categoryOptions.map(category => (
              <Select.Option key={category.value} value={category.value}>
                {category.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the model name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[{ message: "Please enter the series order!" }]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModelsForm;