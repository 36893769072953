import React, { useState } from "react";
import LineColumnArea from "./LineColumnArea";
import { Card, CardBody, Col, Row } from "reactstrap";

// Utility function to filter orders based on date range
// const filterOrdersByDateRange = (orders, range) => {
//     const now = new Date();
//     let startDate;

//     switch (range) {
//         case 'today':
//             startDate = new Date(now.setHours(0, 0, 0, 0));
//             break;
//         case 'last_week':
//             startDate = new Date(now.setDate(now.getDate() - 7));
//             break;
//         case 'last_month':
//             startDate = new Date(now.setMonth(now.getMonth() - 1));
//             break;
//         case 'last_3_months':
//             startDate = new Date(now.setMonth(now.getMonth() - 3));
//             break;
//         case 'last_6_months':
//             startDate = new Date(now.setMonth(now.getMonth() - 6));
//             break;
//         case 'last_year':
//             startDate = new Date(now.setFullYear(now.getFullYear() - 1));
//             break;
//         default:
//             startDate = new Date(0); // Default to start of time
//     }

//     return orders.filter(order => new Date(order.createdAt) >= startDate);
// };
const filterOrdersByDateRange = (orders, range) => {
  const now = new Date();
  let startDate;

  // Create a new Date object for the start date to avoid mutation
  switch (range) {
    case "today":
      startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      break;
    case "last_week":
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      break;
    case "last_month":
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      break;
    case "last_3_months":
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 3);
      break;
    case "last_6_months":
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 6);
      break;
    case "last_year":
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      break;
    default:
      startDate = new Date(0); // Default to start of time
  }

  return orders.filter(
    (order) => new Date(order.createdAt) >= startDate && order.orderStatus === 3
  );
};

// Utility function to process monthly data
const processMonthlyData = (orders) => {
  const monthlyData = orders.reduce((acc, order) => {
    const date = new Date(order.createdAt);
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const monthYear = `${month}-${year}`;

    if (!acc[monthYear]) {
      acc[monthYear] = { basicAmt: 0, couponAmt: 0, totalAmt: 0 };
    }

    acc[monthYear].basicAmt += order.basicAmt;
    acc[monthYear].couponAmt += order.couponAmt;
    acc[monthYear].totalAmt += order.totalAmt;

    return acc;
  }, {});

  const labels = Object.keys(monthlyData);
  const basicAmtData = labels.map((label) => monthlyData[label].basicAmt);
  const couponAmtData = labels.map((label) => monthlyData[label].couponAmt);
  const totalAmtData = labels.map((label) => monthlyData[label].totalAmt);

  return {
    labels,
    datasets: [
      {
        name: "Total Profit",
        type: "column", // Change to line for better visibility
        data: totalAmtData,
        backgroundColor: "rgba(40, 167, 69, 0.6)",
        borderColor: "rgba(40, 167, 69, 1)",
        borderWidth: 2,
      },
      {
        name: "Basic Amount",
        type: "line",
        data: basicAmtData,
        backgroundColor: "rgba(255, 193, 7, 0.6)",
        borderColor: "rgba(255, 193, 7, 1)",
        borderWidth: 1,
      },
      {
        name: "Coupon Amount",
        type: "area",
        data: couponAmtData,
        backgroundColor: "#ff4d4f", 
        borderColor: "#ff4d4f",
        borderWidth: 1,
      },
    ],
  };
};

const OverView = ({ order }) => {
  const [dateRange, setDateRange] = useState("last_year");

  const filteredOrders = filterOrdersByDateRange(order, dateRange);
  const chartData = processMonthlyData(filteredOrders);

  const totalAmount = filteredOrders
    .reduce((acc, order) => acc + order.totalAmt, 0)
    .toLocaleString();
  const basicAmount = filteredOrders
    .reduce((acc, order) => acc + order.basicAmt, 0)
    .toLocaleString();
  const couponAmount = filteredOrders
    .reduce((acc, order) => acc + order.couponAmt, 0)
    .toLocaleString();

  const OverViewData = [
    {
      id: 1,
      title: "Total Profit",
      count: `₹${totalAmount}`,
      percentage: "N/A", // Add logic for percentage change if needed
      color: "success",
    },
    {
      id: 2,
      title: "Basic Amount",
      count: `₹${basicAmount}`,
      percentage: "N/A", // Add logic for percentage change if needed
      color: "warning",
    },
    {
      id: 4,
      title: "Coupon Amount",
      count: `- ₹${couponAmount}`,
      percentage: "N/A", // Add logic for percentage change if needed
      color: "danger",
    },
  ];

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">Overview</h5>
              </div>
              <div className="flex-shrink-0">
                <div>
                  <button
                    type="button"
                    className={`btn btn-soft-secondary btn-sm me-1 ${
                      dateRange === "all" ? "active" : ""
                    }`}
                    onClick={() => setDateRange("all")}
                  >
                    ALL
                  </button>
                  <button
                    type="button"
                    className={`btn btn-soft-primary btn-sm me-1 ${
                      dateRange === "today" ? "active" : ""
                    }`}
                    onClick={() => setDateRange("today")}
                  >
                    Today
                  </button>
                  <button
                    type="button"
                    className={`btn btn-soft-primary btn-sm me-1 ${
                      dateRange === "last_week" ? "active" : ""
                    }`}
                    onClick={() => setDateRange("last_week")}
                  >
                    Week
                  </button>
                  <button
                    type="button"
                    className={`btn btn-soft-primary btn-sm me-1 ${
                      dateRange === "last_month" ? "active" : ""
                    }`}
                    onClick={() => setDateRange("last_month")}
                  >
                    1M
                  </button>
                  <button
                    type="button"
                    className={`btn btn-soft-primary btn-sm me-1 ${
                      dateRange === "last_3_months" ? "active" : ""
                    }`}
                    onClick={() => setDateRange("last_3_months")}
                  >
                    3M
                  </button>
                  <button
                    type="button"
                    className={`btn btn-soft-secondary btn-sm me-1 ${
                      dateRange === "last_6_months" ? "active" : ""
                    }`}
                    onClick={() => setDateRange("last_6_months")}
                  >
                    6M
                  </button>
                  <button
                    type="button"
                    className={`btn btn-soft-secondary btn-sm me-1 ${
                      dateRange === "last_year" ? "active" : ""
                    }`}
                    onClick={() => setDateRange("last_year")}
                  >
                    1Y
                  </button>
                </div>
              </div>
            </div>
            <div>
              <LineColumnArea data={chartData} />
            </div>
          </CardBody>
          <CardBody className="border-top">
            <div className="text-muted text-center">
              <Row>
                {OverViewData.map((item, key) => (
                  <Col md={4} key={key} className="border-end">
                    <div>
                      <p className="mb-2">
                        <i
                          className={
                            "mdi mdi-circle font-size-12 me-1 text-" +
                            item.color
                          }
                        ></i>{" "}
                        {item.title}
                      </p>
                      <h5 className="font-size-16 mb-0">
                        {item.count}{" "}
                        <span className="text-success font-size-12">
                          <i className="mdi mdi-menu-up font-size-14 me-1"></i>
                          {item.percentage} %
                        </span>
                      </h5>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default OverView;

// import React from 'react';
// import LineColumnArea from './LineColumnArea';
// import { Card, CardBody, Col, Row } from "reactstrap";

// // Utility function to process monthly data
// const processMonthlyData = (orders) => {
//     const monthlyData = orders.reduce((acc, order) => {
//         const date = new Date(order.createdAt);
//         const month = date.toLocaleString('default', { month: 'short' });
//         const year = date.getFullYear();
//         const monthYear = `${month}-${year}`;

//         if (!acc[monthYear]) {
//             acc[monthYear] = { basicAmt: 0, couponAmt: 0, totalAmt: 0 };
//         }

//         acc[monthYear].basicAmt += order.basicAmt;
//         // acc[monthYear].advAmt += order.advAmt;
//         acc[monthYear].couponAmt += order.couponAmt;
//         acc[monthYear].totalAmt += order.totalAmt;

//         return acc;
//     }, {});

//     const labels = Object.keys(monthlyData);
//     const basicAmtData = labels.map(label => monthlyData[label].basicAmt);
//     // const advAmtData = labels.map(label => monthlyData[label].advAmt);
//     const couponAmtData = labels.map(label => monthlyData[label].couponAmt);

//     return {
//         labels,
//         datasets: [
//             {
//                 name: "Basic Amount",
//                 type: "column",
//                 data: basicAmtData,
//                 backgroundColor: 'rgba(75, 192, 192, 0.5)',
//                 borderColor: 'rgba(75, 192, 192, 1)',
//                 borderWidth: 1
//             },
//             // {
//             //     name: "Advance Amount",
//             //     type: "line",
//             //     data: advAmtData,
//             //     backgroundColor: 'rgba(255, 159, 64, 0.5)',
//             //     borderColor: 'rgba(255, 159, 64, 1)',
//             //     borderWidth: 1
//             // },
//             {
//                 name: "Coupon Amount",
//                 type: "area",
//                 data: couponAmtData,
//                 backgroundColor: 'rgba(153, 102, 255, 0.5)',
//                 borderColor: 'rgba(153, 102, 255, 1)',
//                 borderWidth: 1
//             }
//         ]
//     };
// };

// const OverView = ({ order }) => {
//     console.log("overview order :", order);

//     const chartData = processMonthlyData(order);

//     const OverViewData = [
//         {
//             id: 1,
//             title: "Total Amount",
//             count: order.reduce((acc, order) => acc + order.totalAmt, 0).toLocaleString(),
//             percentage: "N/A", // or calculate if applicable
//             color: "#ff8c00"
//         },
//         {
//             id: 2,
//             title: "Basic Amount",
//             count: order.reduce((acc, order) => acc + order.basicAmt, 0).toLocaleString(),
//             percentage: "N/A", // or calculate if applicable
//             color: "primary"
//         },
//         // {
//         //     id: 3,
//         //     title: "Advance Amount",
//         //     count: order.reduce((acc, order) => acc + order.advAmt, 0).toLocaleString(),
//         //     percentage: "N/A", // or calculate if applicable
//         //     color: "danger"
//         // },
//         {
//             id: 4,
//             title: "Coupon Amount",
//             count: order.reduce((acc, order) => acc + order.couponAmt, 0).toLocaleString(),
//             percentage: "N/A", // or calculate if applicable
//             color: "success"
//         }
//     ];

//     return (
//         <React.Fragment>
//             <Col xl={8}>
//                 <Card>
//                     <CardBody>
//                         <div className="d-flex align-items-center">
//                             <div className="flex-grow-1">
//                                 <h5 className="card-title">Overview</h5>
//                             </div>
//                             <div className="flex-shrink-0">
//                                 <div>
//                                     <button type="button" className="btn btn-soft-secondary btn-sm me-1">
//                                         ALL
//                                     </button>
//                                     <button type="button" className="btn btn-soft-primary btn-sm me-1">
//                                         1M
//                                     </button>
//                                     <button type="button" className="btn btn-soft-secondary btn-sm me-1">
//                                         6M
//                                     </button>
//                                     <button type="button" className="btn btn-soft-secondary btn-sm me-1 active">
//                                         1Y
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <LineColumnArea data={chartData} />
//                         </div>
//                     </CardBody>
//                     <CardBody className="border-top">
//                         <div className="text-muted text-center">
//                             <Row>
//                                 {OverViewData.map((item, key) => (
//                                     <Col md={4} key={key} className="border-end">
//                                         <div>
//                                             <p className="mb-2">
//                                                 <i className={"mdi mdi-circle font-size-12 me-1 text-" + item.color}></i> {item.title}
//                                             </p>
//                                             <h5 className="font-size-16 mb-0">
//                                                 ₹ {item.count} <span className="text-success font-size-12">
//                                                     <i className="mdi mdi-menu-up font-size-14 me-1"></i>{item.percentage} %
//                                                 </span>
//                                             </h5>
//                                         </div>
//                                     </Col>
//                                 ))}
//                             </Row>
//                         </div>
//                     </CardBody>
//                 </Card>
//             </Col>
//         </React.Fragment>
//     );
// };

// export default OverView;

// import React from 'react';
// import LineColumnArea from './LineColumnArea';

// import {
//     Card,
//     CardBody,
//     Col,
//     Row
// } from "reactstrap";

// // import { OverViewData } from '../../CommonData/Data/index';

// const OverView = ({ order }) => {
//     console.log("overview order :",order);
//     const processOrderData = (orders) => {
//         const labels = orders.map(order => new Date(order.createdAt).toLocaleDateString());
//         const data = orders.map(order => order.basicAmt);

//         return {
//             labels,
//             datasets: [
//                 {
//                     label: 'Basic Amount',
//                     data,
//                     backgroundColor: 'rgba(75, 192, 192, 0.2)',
//                     borderColor: 'rgba(75, 192, 192, 1)',
//                     borderWidth: 1
//                 }
//             ]
//         };
//     };
//     const chartData = processOrderData(order);

//     const OverViewData = [
//         {
//             id: 1,
//             title: "Total Amount",
//             count: order.reduce((acc, order) => acc + order.totalAmt, 0).toLocaleString(),
//             percentage: "N/A", // or calculate if applicable
//             color: "primary"
//         },
//         {
//             id: 2,
//             title: "Basic Amount",
//             count: order.reduce((acc, order) => acc + order.basicAmt, 0).toLocaleString(),
//             percentage: "N/A", // or calculate if applicable
//             color: "light"
//         },
//         {
//             id: 3,
//             title: "Advance Amount",
//             count: order.reduce((acc, order) => acc + order.advAmt, 0).toLocaleString(),
//             percentage: "N/A", // or calculate if applicable
//             color: "danger"
//         },
//         {
//             id: 4,
//             title: "Coupon Amount",
//             count: order.reduce((acc, order) => acc + order.couponAmt, 0).toLocaleString(),
//             percentage: "N/A", // or calculate if applicable
//             color: "success"
//         }
//     ];

//     return (
//         <React.Fragment>
//             <Col xl={8}>
//                 <Card>
//                     <CardBody>
//                         <div className="d-flex align-items-center">
//                             <div className="flex-grow-1">
//                                 <h5 className="card-title">Overview</h5>
//                             </div>
//                             <div className="flex-shrink-0">
//                                 <div>
//                                     <button type="button" className="btn btn-soft-secondary btn-sm me-1">
//                                         ALL
//                                     </button>
//                                     <button type="button" className="btn btn-soft-primary btn-sm me-1">
//                                         1M
//                                     </button>
//                                     <button type="button" className="btn btn-soft-secondary btn-sm me-1">
//                                         6M
//                                     </button>
//                                     <button type="button" className="btn btn-soft-secondary btn-sm me-1 active">
//                                         1Y
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                         <div>
//                             <LineColumnArea data={chartData} />
//                         </div>
//                     </CardBody>
//                     <CardBody className="border-top">
//                         <div className="text-muted text-center">
//                             <Row>
//                                 {OverViewData.map((item, key) => (<Col md={4} key={key} className="border-end">
//                                     <div>
//                                         <p className="mb-2"><i className={"mdi mdi-circle font-size-12 me-1 text-" + item.color}></i> {item.title}</p>
//                                         <h5 className="font-size-16 mb-0">₹ {item.count} <span className="text-success font-size-12"><i className="mdi mdi-menu-up font-size-14 me-1"></i>{item.percentage} %</span></h5>
//                                     </div>
//                                 </Col>))}
//                             </Row>
//                         </div>
//                     </CardBody>
//                 </Card>
//             </Col>
//         </React.Fragment>
//     );
// };

// export default OverView;
