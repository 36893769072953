import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Switch,
} from "antd";
import {
  HomeOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  getCoupons, addCoupons, updateCoupons, deleteCoupons,
  updateStatusCoupon
} from "../../store/actions/coupons.js";
import { Spinner } from "reactstrap";
import ProductsForm from "./CouponsForm.js";
import moment from "moment-timezone";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";

const { Title } = Typography;

const Coupons = () => {
  document.title = "Coupons List";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dispatch = useDispatch();
  const coupons = useSelector((state) => state.coupons.list);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getCoupons());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);


  const handleCreate = async (values) => {
    const formData = new FormData();
  
    Object.keys(values).forEach(key => {
      if (values[key] !== undefined && values[key] !== null) {
        formData.append(key, values[key]);
      }
    });
  
    try {
      if (selectedRecord) {
        formData.append('id', selectedRecord._id);
        await dispatch(updateCoupons(formData));
        await dispatch(getCoupons());
      } else {
        await dispatch(addCoupons(formData));
        await dispatch(getCoupons());
      }
      setIsModalVisible(false);
      return { success: true, message: 'Coupon operation completed successfully.' };
    } catch (error) {
      console.error('Error in coupon operation:', error);
      return { success: false, message: 'An error occurred during the coupon operation.' };
    }
  };


  const handleDelete = async (id) => {
    await dispatch(deleteCoupons(id));
  };

  const handleChangeStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    const updatedData = { _id: id, status: newStatus }; // Create an object for update
    console.log("new status  :", newStatus);
    
    await dispatch(updateStatusCoupon(updatedData));
    await dispatch(getCoupons());
  };

  const renderCell = (text) => (text ? text : 'N/A');

  let columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Description",
      dataIndex: "about",
      key: "about",
      sorter: (a, b) => a.about.localeCompare(b.about),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Discount Percentage",
      dataIndex: "perc",
      key: "perc",
      sorter: (a, b) => a.perc - b.perc,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Amount",
      dataIndex: "amt",
      key: "amt",
      sorter: (a, b) => a.perc - b.perc,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Minimum Amount",
      dataIndex: "minAmt",
      key: "minAmt",
      sorter: (a, b) => a.minAmt - b.minAmt,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Expiry Date",
      dataIndex: "expDate",
      key: "expDate",
      render: (date) => moment(date || undefined).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => moment(a.expDate).unix() - moment(b.expDate).unix(),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Is Expired",
      dataIndex: "isExpired",
      key: "isExpired",
      render: (isExpired) => (isExpired ? "Yes" : "No"),
      sorter: (a, b) => (a.isExpired === b.isExpired ? 0 : a.isExpired ? 1 : -1),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Is Redeemed",
      dataIndex: "isRedeemed",
      key: "isRedeemed",
      render: (isRedeemed) => (isRedeemed ? "Yes" : "No"),
      sorter: (a, b) => (a.isRedeemed === b.isRedeemed ? 0 : a.isRedeemed ? 1 : -1),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={text === 1}
          onChange={() => handleChangeStatus(record._id, text)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => {
              handleDelete(record._id)
            }}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Coupons</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Coupons
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Coupon
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={coupons.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <ProductsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
    </div>
  );
};

export default Coupons;
