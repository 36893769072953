import React from "react";
import ReactApexChart from "react-apexcharts";

const LineColumnArea = ({ data }) => {
    const options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false
            },
        },
        stroke: {
            width: [0, 1, 1],
            dashArray: [0, 0, 5],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: "18%",
            },
        },
        legend: {
            show: true,
        },
        colors: ["#0ab39c", "#ff8c00", "#3f51b5"],
        fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        xaxis: {
            categories: data.labels,
            type: "category",
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " ₹";
                    }
                    return y;
                },
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={data.datasets}
                type="line"
                height="350"
                stacked="false"
                className="apex-charts"
            />
        </React.Fragment>
    );
}

export default LineColumnArea;






// import React from "react";
// import ReactApexChart from "react-apexcharts";


// const LineColumnAreaData = {
//   series: [
//     {
//       name: "Expenses",
//       type: "column",
//       data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 18],
//     },
//     {
//       name: "Maintenance",
//       type: "area",
//       data: [44, 55, 41, 42, 22, 43, 21, 41, 56, 27, 43, 27],
//     },
//     {
//       name: "Profit",
//       type: "line",
//       data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
//     },
//   ],
//   options: {
//     chart: {
//       height: 350,
//         type: 'line',
//         stacked: false,
//         toolbar: {
//             show: false
//         },
//     },
//     stroke: {
//       width: [0, 1, 1],
//         dashArray: [0, 0, 5],
//         curve: 'smooth'
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: "18%",
//       },
//     },
//     legend: {
//       show: false,
//   },
//     colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],

//     fill: {
//       opacity: [0.85, 0.25, 1],
//       gradient: {
//         inverseColors: false,
//         shade: "light",
//         type: "vertical",
//         opacityFrom: 0.85,
//         opacityTo: 0.55,
//         stops: [0, 100, 100, 100],
//       },
//     },
//     labels: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec"
//     ],
//     markers: {
//       size: 0,
//     },
//     xaxis: {
//       type: "month",
//     },
//     tooltip: {
//       shared: true,
//       intersect: false,
//       y: {
//         formatter: function (y) {
//           if (typeof y !== "undefined") {
//             return y.toFixed(0) + " points"
//           }
//           return y
//         },
//       },
//     },
//     grid: {
//       borderColor: "#f1f1f1",
//     },
//   },
// }

// const LineColumnArea = () => {
//   return(
//     <React.Fragment>
//         <ReactApexChart
//           options={LineColumnAreaData.options}
//           series={LineColumnAreaData.series}
//           type="line"
//           height="350"
//           stacked= "false"
//           className="apex-charts"
//         />
//       </React.Fragment>
//   )
// }

// export default LineColumnArea;