import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, InputNumber, DatePicker, Row, Col, Radio } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const CouponsForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const [discountType, setDiscountType] = useState('percentage');
  const dispatch = useDispatch();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        expDate: record.expDate ? moment(record.expDate) : null,
        discountType: record.discountType || 'percentage'
      });
      setDiscountType(record.discountType || 'percentage');
    } else {
      form.resetFields();
    }
  }, [record, form]);

  const disablePastDates = (current) => {
    return current && current < moment().startOf('day');
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (values.expDate) {
        values.expDate = values.expDate.format('YYYY-MM-DD');
      }
      await onCreate(values);
      form.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Coupon" : "Add Coupon"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="coupon_form"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter the title!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="about"
              label="Description"
              rules={[{ required: true, message: 'Please enter a description!' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
        <Form.Item
          name="expDate"
          label="Expiry Date"
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD"
            disabledDate={disablePastDates}
            style={{width:'100%'}}
          />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
                name="minAmt"
                label="Minimum Amount"
                rules={[{ required: true, message: 'Please enter the minimum amount!' }]}
              >
                <InputNumber style={{width:'100%'}} min={0} />
              </Form.Item>
              </Col>
              </Row>
        <Form.Item
          name="discountType"
          label="Discount Type"
          initialValue="percentage"
        >
          <Radio.Group onChange={handleDiscountTypeChange}>
            <Radio value="percentage">Percentage</Radio>
            <Radio value="amount">Amount</Radio>
          </Radio.Group>
        </Form.Item>

        {discountType === 'percentage' ? (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="perc"
                label="Discount Percentage"
                rules={[{ required: true, message: 'Please enter the discount percentage!' }]}
              >
                <InputNumber style={{width:'49%'}} min={0} max={100} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="amt"
                label="Amount"
                rules={[{ required: true, message: 'Please enter the amount!' }]}
              >
                <InputNumber style={{width:'49%'}} min={0} />
              </Form.Item>
            </Col>
          </Row>
        )}

        
      </Form>
    </Modal>
  );
};

export default CouponsForm;




// import React, { useEffect } from 'react';
// import { Form, Input, Modal, InputNumber, DatePicker } from 'antd';
// import { useDispatch } from 'react-redux';
// import moment from 'moment';

// const CouponsForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (record) {
//       form.setFieldsValue({
//         ...record,
//         expDate: record.expDate ? moment(record.expDate) : null
//       });
//     } else {
//       form.resetFields();
//     }
//   }, [record, form]);

//   const disablePastDates = (current) => {
//     return current && current < moment().startOf('day');
//   };

//   const handleSubmit = async () => {
//     try {
//       const values = await form.validateFields();
//       if (values.expDate) {
//         values.expDate = values.expDate.format('YYYY-MM-DD');
//       }
//       await onCreate(values);
//       form.resetFields();
//     } catch (error) {
//       console.error('Validation failed:', error);
//     }
//   };

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Coupon" : "Add Coupon"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={handleSubmit}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="coupon_form"
//       >
//         <Form.Item
//           name="title"
//           label="Title"
//           rules={[{ required: true, message: 'Please enter the title!' }]}
//         >
//           <Input />
//         </Form.Item>

//         <Form.Item
//           name="about"
//           label="Description"
//           rules={[{ required: true, message: 'Please enter a description!' }]}
//         >
//           <Input.TextArea rows={4} />
//         </Form.Item>

//         <Form.Item
//           name="perc"
//           label="Discount Percentage"
//           type="number"
//           rules={[{ required: true, message: 'Please enter the discount percentage!' }]}
//         >
//           <InputNumber min={0} max={100} />
//         </Form.Item>

//         <Form.Item
//           name="minAmt"
//           label="Minimum Amount"
//           type="number"
//           rules={[{ required: true, message: 'Please enter the minimum amount!' }]}
//         >
//           <InputNumber min={0} />
//         </Form.Item>

//         <Form.Item
//           name="expDate"
//           label="Expiry Date"
//         >
//           <DatePicker
//             showTime
//             format="YYYY-MM-DD"
//             disabledDate={disablePastDates}
//           />
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default CouponsForm;

