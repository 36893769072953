import {
    ADD_COUPON,
    DELETE_COUPON,
    GET_COUPONS,
    UPDATE_COUPON
} from "../types";
import {setAlert} from "./alert";
import axios from "../../axios";
import Swal from 'sweetalert2';

export const getCoupons = () => async (dispatch) => {
    try {
        const res = await axios.get("coup/get");
        if (res.data.code === 1) {
            dispatch({type: GET_COUPONS, payload: res.data.data});
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addCoupons = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("coup/add", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        if (res.data.code === 1) {
            dispatch({type: ADD_COUPON, payload: res.data.data});
            Swal.fire({
              title: 'Success!',
              text: res.data.message,
              icon: 'success',
          });
            dispatch(setAlert(res.data.message, 'success'));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const updateCoupons = (formData) => async (dispatch) => {
    try {
      const res = await axios.post("coup/update", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (res.data.code === 1) {
        dispatch({type: UPDATE_COUPON, payload: res.data.data});
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
        });
        dispatch(setAlert(res.data.message, 'success'));
      } else {
        Swal.fire({
          title: 'warning!',
          text: res.data.message,
          icon: 'warning',
      });
        dispatch(setAlert(res.data.message, "warning"));
      }
    } catch (err) {
      dispatch(setAlert(err.message, 'danger'));
    }
  };

  export const updateStatusCoupon = (formData) => async (dispatch) => {
    try {
  
      const updatedFormData = {
        id: formData._id,
        ...(formData.status !== undefined && { status: formData.status }),
      };
      const res = await axios.post("coup/update", updatedFormData);
      if (res.data.code === 1) {
        dispatch({ type: UPDATE_COUPON, payload: res.data.data });
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
      });
        dispatch(setAlert(res.data.message, "success"));
      } else {
        dispatch(setAlert(res.data.message, "warning"));
        Swal.fire({
          title: 'warning!',
          text: res.data.message,
          icon: 'warning',
      });
      }
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };

export const deleteCoupons = (_id) => async (dispatch) => {
    try {
        if (!_id) {
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.post(`coup/delete/${_id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_COUPON, payload: {_id}});
            dispatch(setAlert(res.data.message, 'success'));
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};





