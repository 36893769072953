import {  ADD_CITY, DELETE_CITY, GET_CITIES, UPDATE_CITY,  } from "../types";

const initialState = {list: [], single: null, cities: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_CITIES:
            return {...state, list: payload};

        case UPDATE_CITY:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case ADD_CITY:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case DELETE_CITY:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== payload.id),
            };

        default:
            return state;
    }
};

export default reducer;
