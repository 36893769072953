import {Button} from "antd";

export const dateSorter = (columnName) => (a, b) => {
    if (a[columnName] === null || b[columnName] === null) {
        return 0; // Treat nulls as equal
    }
    const dateA = new Date(a[columnName]);
    const dateB = new Date(b[columnName]);
    return dateA - dateB;
};
export const stringSorter = (columnName) => (a, b) => {
    if (a[columnName] === null || b[columnName] === null) {
        return 0; // Treat nulls as equal
    }
    return a[columnName].localeCompare(b[columnName]);
};
export const numberSort = (columnName) => (a, b) => {
    const valueA = getNumberValue(a, columnName);
    const valueB = getNumberValue(b, columnName);

    return valueA - valueB;
};
const getNumberValue = (obj, columnName) => {
    const value = obj[columnName];
    if (typeof value === 'number') {
        return value;
    } else if (typeof value === 'string') {
        const parsedValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
        return isNaN(parsedValue) ? 0 : parsedValue;
    }
    return 0;
};
export const dateTimeSort = (columnName) => (a, b) => {
    if (a[columnName] === null || b[columnName] === null) {
        return 0; // Treat nulls as equal
    }

    if (a[columnName] !== null && b[columnName] !== null) {
        const dateA = new Date(a[columnName]);
        const dateB = new Date(b[columnName]);

        return dateA - dateB;
    }
};

export const firstDayOfMonth = () => {
    const date = new Date();
    date.setDate(1);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = '01'; // The first day of the month
    return `${year}-${month}-${day}`;
};

export function formatDate(dateString) {
    // Check if dateString is null or undefined
    if (dateString === null || dateString === undefined) {
        return ''; // Return an empty string or any other default value
    }

    // Check if dateString contains time information
    const timeRegex = /\d{2}:\d{2}:\d{2}/;
    const hasTime = timeRegex.test(dateString);

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    if (hasTime) {
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    } else {
        return `${year}-${month}-${day}`;
    }
}

export function formatDateWithTime(dateString,wantTime) {
    // Check if dateString is null or undefined
    if (dateString === null || dateString === undefined) {
        return ''; // Return an empty string or any other default value
    }

    // Check if dateString contains time information
    const timeRegex = /\d{2}:\d{2}:\d{2}/;
    const hasTime = timeRegex.test(dateString);

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    if (hasTime && wantTime) {
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    } else {
        return `${year}-${month}-${day}`;
    }
}

export function customFilterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
    return (
        <div style={{ padding: 8 }}>
            <input
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
                type="primary"
                onClick={() => confirm()}
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters()}
                size="small"
                style={{ width: 90 }}
            >
                Reset
            </Button>
        </div>
    );
}





