import React, { useEffect } from 'react';
import { Form, Input, Modal, Upload, Button, Select, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const BannerForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      _id: record?._id,
      name: record?.name,
      url: record?.url,
      reference: record?.reference,
      image: record?.image ? [{ url: record.image }] : [],
    });
  }, [record, form]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.fileList) {
      return e.fileList;
    }
    return [];
  };

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpeg';
      if (!isPNG || !isJPG) {
        message.error(`${file.name} is not a png/jpeg file`);
      }
      return isPNG || isJPG || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };
  
  return (
    <Modal
      visible={visible}
      title={record ? "Update Banner" : "Add Banner"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="brand_form"
        initialValues={record}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the brand name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="url"
          label="url"
          rules={[{ required: true, message: 'Please enter the brand name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="reference" label="Reference">
              <Select placeholder="Select a Reference type">
                <Select.Option value="Slider">Slider</Select.Option>
                <Select.Option value="Banner">Banner</Select.Option>
                <Select.Option value="Testimonial">Testimonial</Select.Option>
                <Select.Option value="Logo">Logo</Select.Option>
                {/* Add other network types if needed */}
              </Select>
            </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="Select a single image to upload"
        >
          <Upload
            name="image"
            listType="picture"
            accept="image/png, image/jpeg"
            {...props}
            // beforeUpload={() => false} // Prevent automatic upload
            maxCount={1} // Restrict to one file
            // fileList={form.getFieldValue('image')}
            // customRequest={({ file, onSuccess, onError }) => {
            //   // Simulate an upload request
            //   setTimeout(() => onSuccess("ok"), 0);
            // }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BannerForm;