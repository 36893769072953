import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/actions/products";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const { Title } = Typography;

const ProductsPanel = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector((state) => state.products.list);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch all products in a single request
        await dispatch(getProducts());
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const productsCounts = useMemo(() => {
    if (!products.length) return {};

    const now = new Date();
    const today = now.toISOString().split('T')[0];
    const last7Days = new Date(now.setDate(now.getDate() - 7)).toISOString().split('T')[0];
    // const lastMonth = new Date(now.setMonth(now.getMonth() - 1)).toISOString().split('T')[0];
      // Calculate the fromDate and toDate for last month
      const nowforlastMonth = moment();
      const fromDate = nowforlastMonth.clone().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const toDate = nowforlastMonth.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

    return {
      active: products.filter(c => c.status === 1).length,
      inactive: products.filter(c => c.status === 0).length,
      today: products.filter(c => c.status === 1 && c.createdAt.split('T')[0] === today).length,
      last7Days: products.filter(c => c.status === 1 && c.createdAt.split('T')[0] >= last7Days).length,
      lastMonth: products.filter(c => c.status === 1 && c.createdAt.split('T')[0] >= fromDate && c.createdAt.split('T')[0] <= toDate).length
    };
  }, [products]);

  const handleCardClick = (type) => {
    if (type === "active") {
      navigate('/products');  // Navigate to the /products route
    }
    if (type === "inactive") {
      navigate('/products/inactive');  // Navigate to the /products/inactive route
    }
    if (type === "today") {
      navigate('/products/today'); 
    }
    if (type === "last7Days") {
      navigate('/products/last7Days'); 
    }
    if (type === "lastMonth") {
      navigate('/products/lastMonth'); 
    }
  };

  const renderCustomerCard = (title, count, type) => (
    <Col xl={4} sm={12}>
      <Card onClick={() => handleCardClick(type)}>
        <CardBody>
          <div className="d-flex text-muted">
            <div className="flex-shrink-0 me-3 align-self-center">
              <div className="avatar-sm">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="ri-group-line"></i>
                </div>
              </div>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="mb-1">{title}</p>
              <h5 className="mb-3">{isLoading ? 'Loading...' : count}</h5>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );

  return (
    <React.Fragment>
      <Row>
        <Title level={5}>Products</Title>
        {renderCustomerCard("Active Products", productsCounts.active, "active")}  {/* Because Default Products is Active */}
        {renderCustomerCard("Inactive Products", productsCounts.inactive, "inactive")}
        {renderCustomerCard("Today's Products", productsCounts.today, "today")}
        {renderCustomerCard("Last 7 Days Products", productsCounts.last7Days, "last7Days")}
        {renderCustomerCard("Last Month Products", productsCounts.lastMonth, "lastMonth")}
      </Row>
    </React.Fragment>
  );
};

export default ProductsPanel;