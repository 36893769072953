import Swal from "sweetalert2";
import axios from "../../axios";
import { ADD_BRANCH, DELETE_BRANCH, GET_BRANCH, UPDATE_BRANCH } from "../types";
import { setAlert } from "./alert";

export const addBranch = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("branch/add", formData);
    if (res.data.code === 1) {
      dispatch({ type: ADD_BRANCH, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
    } 
  } catch (err) {
    const errorMessage =
    err.response?.data?.message || "An unexpected error occurred";
    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
};
export const getBranch = () => async (dispatch) => {
  try {
    const res = await axios.get("branch/get");
    console.log("branch :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_BRANCH, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};


export const updateBranch = (formData) => async (dispatch) => {
  try {
    const updatedFormData = {
      id: formData._id,
      ...(formData.name && { name: formData.name }),
      ...(formData.address && { address: formData.address }),
      ...(formData.pinCode && { pinCode: formData.pinCode }),
      ...(formData.location && { location: formData.location }),
      ...(formData.status !== undefined && { status: formData.status }),
      // ...(formData.countryId && { countryId: formData.countryId }),
    };
    console.log("updatedFormData :", updatedFormData);

    const res = await axios.post("branch/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_BRANCH, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: "warning!",
        text: res.data.message,
        icon: "warning",
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteBranch = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`branch/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_BRANCH, payload: { id } });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
