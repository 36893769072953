import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomProductDatatable/index.js";
// import 'antd/dist/reset.css';
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Image,
  Modal,
  Rate,
} from "antd";
import {
  HomeOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import {
  deleteProducts,
  getProducts,
  updateProducts,
  addProducts,
  deleteImage,
  getDashboardProducts
} from "../../store/actions/products.js";
import { Spinner } from "reactstrap";
import ProductsForm from "./ProductsForm.js";
import ProductsUpdateForm from "./ProductsUpdateForm.js";
import moment from "moment-timezone";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import CustomToggleSwitch from "../../components/CustomToggleSwitch.js";
import { getBrands } from "../../store/actions/brand.js";
import { getModels } from "../../store/actions/model.js";
import { getSeries } from "../../store/actions/series.js";

const { Title } = Typography;

const Products = () => {
  document.title = "Products List";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.list);
  const now = moment(); // Ensure this is a moment object
  const fromDate = now.clone().subtract(6, 'days').startOf('day').toISOString();
  const toDate = now.endOf('day').toISOString();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getDashboardProducts(1, fromDate, toDate));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);


  const handleCreate = async (values) => {
    const formData = new FormData();
    console.log("Form values:", values);
    
    // Append other form fields to formData
    // Object.keys(values).forEach(key => {
    Object.keys(values || {}).forEach(key => {
      if (key === 'images') {
        if (values.images && Array.isArray(values.images)) {
          values.images.forEach(file => {
            if (file.originFileObj) {
              formData.append('images', file.originFileObj);
            }
          });
        }
      } else if (values[key] !== "" && values[key] !== null) {
        formData.append(key, values[key]);
      }
    });

    if (values.imeis && Array.isArray(values.imeis)) {
        values.imeis.forEach((imei, index) => {
          if (imei.imei) {
            formData.append(`imeis[${index}]`, imei.imei);
          }
      });
    }

      await dispatch(addProducts(formData));
      await dispatch(getDashboardProducts(1, fromDate, toDate));
      await dispatch(getBrands());
      await dispatch(getModels());
      await dispatch(getSeries())

    setIsModalVisible(false);
  };
  

  const handleUpdate = async (values) => {
    const formData = new FormData();
    console.log("values :", values);
  
    // Object.keys(values).forEach(key => {
    Object.keys(values).forEach(key => {
      if (key === 'images') {
        if (values.images && Array.isArray(values.images)) {
          values.images.forEach(file => {
            if (file.originFileObj) {
              formData.append('images', file.originFileObj);
            }
          });
        }
      } else if (values[key] !== undefined && values[key] !== null) {
        formData.append(key, values[key] === "" ? " " : values[key]); // Replace empty strings with " " if needed
      }
      // } else if (values[key] !== undefined && values[key] !== null) {
      //   formData.append(key, values[key]);
      // }
    });
  
    if (selectedRecord) {
      formData.append('id', selectedRecord._id);
      await dispatch(updateProducts(formData));
      await dispatch(getDashboardProducts(1, fromDate, toDate));
    } 
    
   
    setIsUpdateModalVisible(false);

  };


  const handleChangeStatus = async (id, status) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('status', status);
    
    await dispatch(updateProducts(formData));
    await dispatch(getDashboardProducts(1, fromDate, toDate));
  };

  const handleDelete = async (id) => {
    await dispatch(deleteProducts(id));
  };

  const handleDeleteImage = async (imageId) => {
    await dispatch(deleteImage(imageId));
    setImages(images.filter((img) => img._id !== imageId));
  };

  const handleImageModalOpen = (record) => {
    setSelectedRecord(record);
    setImages(record.images || []);
    setIsImageModalVisible(true);
  };

  const handleImageModalClose = () => {
    setIsImageModalVisible(false);
    setSelectedImage(null);
  };

  function toTitleCase(text) {
    if (!text) return text;
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Image",
      dataIndex: "images",
      key: "image",
      render: (images) =>
        images && images.length > 0 ? (
          <Image
            src={images[0].src}
            alt="First Image"
            preview={false}
            style={{ width: "100px", height: "auto", cursor: "pointer" }}
            onClick={() => handleImageModalOpen({ images })}
          />
        ) : (
          <span>No Image</span>
        ),
    },
    {
      title: "Brand",
      dataIndex: "brandName",
      key: "brandName",
      sorter: stringSorter("brandName"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? toTitleCase(text) : text),
    },
    {
      title: "Model",
      dataIndex: "modelName",
      key: "modelName",
      sorter: stringSorter("modelName"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? toTitleCase(text) : text),
    },
    {
      title: "Series",
      dataIndex: "seriesName",
      key: "seriesName",
      sorter: stringSorter("seriesName"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? toTitleCase(text) : text),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      sorter: stringSorter("color"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? toTitleCase(text) : text),
    },
    {
      title: "Sold Status",
      dataIndex: "isSold",
      key: "isSold",
      render: (isSold) => (
        <span style={{ color: isSold ? 'red' : 'black' }}>
          {isSold ? "Sold" : "Not Sold"}
        </span>
      ),
      sorter: (a, b) => a.isSold - b.isSold,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: stringSorter("category"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? toTitleCase(text) : text),
    },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    //   sorter: (a, b) => a.price - b.price,
    //   sortDirections: ["ascend", "descend"],
    // },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      sorter: stringSorter("storage"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      sorter: stringSorter("imei"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (text ? toTitleCase(text) : text),
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "network",
      sorter: stringSorter("network"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "OS",
      dataIndex: "osNo",
      key: "osNo",
      sorter: stringSorter("osNo"),
      sortDirections: ["ascend", "descend"],
    },
    // {
    //   title: "Qty",
    //   dataIndex: "qty",
    //   key: "qty",
    //   sorter: stringSorter("qty"),
    //   sortDirections: ["ascend", "descend"],
    // },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (text, record) => {
          const avgRating = record.avgRating;
          // const totalRatings = record.ratings.length;
          return (
            <div>
              <Rate allowHalf value={avgRating} disabled /> ({avgRating})
            </div>
          );
      },
  },
  // {
  //   title: "YouTube URL",
  //   dataIndex: "youtubeUrl",
  //   key: "youtubeUrl",
  //   sorter: stringSorter("youtubeUrl"),
  //   sortDirections: ["ascend", "descend"],
  //   render: (text) => (
  //     <a href={text} target="_blank" rel="noopener noreferrer">
  //       Open in <YoutubeOutlined /> YouTube
  //     </a>
  //   ),
  // },
  {
    title: "YouTube URL",
    dataIndex: "youtubeUrl",
    key: "youtubeUrl",
    sorter: stringSorter("youtubeUrl"),
    sortDirections: ["ascend", "descend"],
    render: (text) =>
      text && text.includes("https://www.youtube.com/") ? (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Open in <YoutubeOutlined /> YouTube
        </a>
      ) : (
        text
      ),
  },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: stringSorter("price"),
      sortDirections: ["ascend", "descend"],
    },
    // {
    //   title: "In Stock",
    //   dataIndex: "inStock",
    //   key: "inStock",
    //   render: (inStock) => (inStock ? "Yes" : "No"),
    //   sorter: (a, b) => (a.inStock === b.inStock ? 0 : a.inStock ? -1 : 1),
    //   sortDirections: ["ascend", "descend"],
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <CustomToggleSwitch
          record={record}
          onChangeStatus={handleChangeStatus}
        />
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsUpdateModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
        <Breadcrumb.Item>Last 7 Days</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Products
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Product
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={products.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <ProductsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
      <ProductsUpdateForm
        visible={isUpdateModalVisible}
        onCreate={handleUpdate}
        onCancel={() => setIsUpdateModalVisible(false)}
        record={selectedRecord}
      />

      <Modal
        title="Image Preview"
        visible={isImageModalVisible}
        onCancel={handleImageModalClose}
        footer={null}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {images.length > 0 ? (
            images.map((image) => (
              <div key={image._id} style={{ position: "relative" }}>
                <Image width={200} src={image.src} alt="Image" />
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteImage(image._id)}
                  style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
                />
              </div>
            ))
          ) : (
            <span>No images available</span>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Products;