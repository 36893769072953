import Swal from "sweetalert2";
import axios from "../../axios";
import { ADD_MODEL, DELETE_MODEL, GET_MODELS, UPDATE_MODEL } from "../types";
import { setAlert } from "./alert";

export const addModel = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("model/add", formData);
    if (res.data.code === 1) {
      dispatch({ type: ADD_MODEL, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
    if (res.data.code === 0) {
      Swal.fire({
        title: "Error!",
        text: res.data.message || "An unexpected error occurred",
        icon: "error",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
    err.response?.data?.message || "An unexpected error occurred";
    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
};
export const getModels = () => async (dispatch) => {
  try {
    const res = await axios.get("model/get");
    console.log("brand :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_MODELS, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
export const updateModel = (formData) => async (dispatch) => {
  try {

    const updatedFormData = {
      id: formData._id,
      ...(formData.name && { name: formData.name }),
      ...(formData.category && { category: formData.category }),
      ...(formData.status !== undefined && { status: formData.status }),
      ...(formData.order !== undefined && { order: formData.order }),
    };
    
    const res = await axios.post("model/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_MODEL, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
      if (res.data.code === 0) {
        Swal.fire({
          title: 'warning!',
          text: res.data.message,
          icon: 'warning',
      });
      }
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
      err.response?.data?.message || "An unexpected error occurred";
      
    // Check if the message is an object and handle it
    const message = typeof errorMessage === 'object' ? errorMessage.message : errorMessage;

    Swal.fire({
      title: "Error!",
      text: message, // Ensure the message is a string
      icon: "error",
    });
  }
};


export const deleteModel = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`model/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_MODEL, payload: { id } });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    }
    if (res.data.code === 0) {
      Swal.fire({
        title: "Error!",
        text: res.data.message || "An unexpected error occurred",
        icon: "error",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
      err.response?.data?.message || "An unexpected error occurred";
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
      });
  }
};
