import React, { useState, useEffect } from "react";
import { Switch } from "antd";

const CustomToggleSoldSwitch = ({ record, onChangeStatus }) => {
  const [isActive, setIsActive] = useState(false); // Default to false for isSold

  useEffect(() => {
    if (record) {
      setIsActive(record.isSold === 1); // Convert 1/0 to true/false for the Switch
    }
  }, [record]);

  const handleChange = (checked) => {
    setIsActive(checked);
    // Call parent function to handle status change
    onChangeStatus(record._id, checked ? 1 : 0); // Pass 1/0 based on the checked state
  };

  return (
    <div>
      <Switch
        checked={isActive} // true or false for the Switch
        onChange={handleChange}
      />
    </div>
  );
};

export default CustomToggleSoldSwitch;