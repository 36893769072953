import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    customFilterDropdown,
  stringSorter,
} from "../../utils/functions.js";
import DataTable from "../../components/ActiveCustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { addCustomer,  getCustomers, getDashboardCustomers, updateCustomers, updateCustomerStatus } from "../../store/actions/customers.js";
import { Spinner } from "reactstrap";
import CustomerForm from './CustomerForm.js';
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import CustomToggleSwitch from "../../components/CustomToggleSwitch.js";
import moment from "moment-timezone";

// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
const { Title } = Typography;


const ToadyActiveCustomers = () => {
  document.title = "Last Month Customers List";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const dispatch = useDispatch();
  const customersOrders = useSelector((state) => state.customers.list);
  const now = moment(); // Ensure this is a moment object
  // const fromDate = now.clone().subtract(6, 'days').startOf('day').toISOString();
  // const toDate = now.endOf('day').toISOString();
  // Calculate the fromDate as the start of the last month
  const fromDate = now.clone().subtract(1, 'month').startOf('month').toISOString();

  // Calculate the toDate as the end of the last month
  const toDate = now.clone().subtract(1, 'month').endOf('month').toISOString();

  useEffect(() => {
    const fetchCustomerss = async () => {
      try {
        
        // console.log("today :", today);
        await dispatch(getDashboardCustomers(1, fromDate, toDate));
        // await dispatch(getCustomers());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchCustomerss();
  }, [dispatch]);


  const handleCreate = async (values) => {
    try {
      if (selectedRecord) {
        await dispatch(updateCustomers({ ...selectedRecord, ...values }));
      } else {
        await dispatch(addCustomer(values));
      }
      // await dispatch(getCustomers());
      await dispatch(getDashboardCustomers(1,  fromDate, toDate));
    } catch (error) {
      console.error("Failed to save customer:", error);
    } finally {
      setSelectedRecord(null);
      setIsModalVisible(false);
    }
  };

  const handleChangeStatus = async (id, status) => {
    const formData = new FormData();
  
    formData.append('_id', id);
    formData.append('status', status);
    
    await dispatch(updateCustomerStatus(id, status));
    // await dispatch(getCustomers());
    await dispatch(getDashboardCustomers(1,  fromDate, toDate));
  };

  // const handleDelete = async (key) => {
  //   await dispatch(deleteCustomers(key));
  // };

  useEffect(() => {
    const fetchAmazonOrders = async () => {
      try {
        // await dispatch(getCustomers());
        await dispatch(getDashboardCustomers(1,  fromDate, toDate));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchAmazonOrders();
  }, [dispatch]);

  useEffect(() => {
    // console.log(customersOrders);
  }, [customersOrders]);

  const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: stringSorter("mobile"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (
        <span  style={{ height: '20px', lineHeight: '15px' }} >
          {text || 'N/A'}
        </span>
        // <span className="badge rounded-pill bg-info text-white" style={{ height: '20px', lineHeight: '15px' }} >
        //   {text || 'N/A'}
        // </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filters: [
        ...[...new Set((customersOrders || []).map(order => order.email))]
            .filter(email => email !== null && email !== undefined)
            .map(email => ({
                text: email || 'N/A',
                value: email,
            })),
    ],
    onFilter: (value, record) => record.email === value,
    sorter: stringSorter("email"),
    sortDirections: ["ascend", "descend"],
    render: (text, record) => (
        // <span className="badge rounded-pill bg-primary text-white" style={{ height: '20px', lineHeight: '15px' }} >{record.email || 'N/A'}</span>
        <span style={{ height: '20px', lineHeight: '15px' }} >{record.email || 'N/A'}</span>
    ),
    },
    {
      title: "city",
      dataIndex: "city",
      key: "city",
      filterDropdown: customFilterDropdown,
      onFilter: (value, record) => record.city.includes(value),
      sorter: stringSorter("city"),
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
          // <span className="badge rounded-pill bg-primary text-white" style={{ height: '20px', lineHeight: '15px' }} >{record.city}</span>
          <span style={{ height: '20px', lineHeight: '15px' }} >{record.city}</span>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      filters: [
        ...[...new Set((customersOrders || []).map(order => order.state))]
            .filter(state => state !== null && state !== undefined)
            .map(state => ({
                text: state || 'N/A',
                value: state,
            })),
    ],
    onFilter: (value, record) => record.shipToState === value,
    sorter: stringSorter("shipToState"),
    sortDirections: ["ascend", "descend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      filters: [
        ...[...new Set((customersOrders || []).map(order => order.country))]
            .filter(country => country !== null && country !== undefined)
            .map(country => ({
                text: country || 'N/A',
                value: country,
            })),
    ],
    onFilter: (value, record) => record.country === value,
    sorter: stringSorter("country"),
    sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <CustomToggleSwitch
          record={record}
          onChangeStatus={handleChangeStatus}
        />
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record.key)}
          >
            <Button type="link" danger><DeleteOutlined /></Button>
          </Popconfirm> */}
        </Space>
      ),
    }
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Customer</Breadcrumb.Item>
        <Breadcrumb.Item>Last Month Customers</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Customers
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                ADD NEW CUSTOMER
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={customersOrders.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <CustomerForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
    </div>
  );
};

export default ToadyActiveCustomers;
