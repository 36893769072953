import React, { useEffect } from 'react';
import { Form, Modal, InputNumber, Switch, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const ProductOrderForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        expDate: record.expDate ? moment(record.expDate) : null,
        isApproved: record.isApproved || false,
        isPaid: record.isPaid || false,
        isCancelled: record.isCancelled || false
      });
    } else {
      form.resetFields();
    }
  }, [record, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (values.expDate) {
        values.expDate = values.expDate.format('YYYY-MM-DD');
      }
      await onCreate(values);
      form.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Order" : "Add Order"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="Order_form"
      >
       <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="advAmt"
          label="Advance Amount"
          valuePropName="checked"
        >
          <InputNumber />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="isApproved"
          label="Is Approved"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="isPaid"
          label="Is Paid"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="isCancelled"
          label="Is Cancelled"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Col>
    </Row>
      </Form>
    </Modal>
  );
};

export default ProductOrderForm;


