import { ADD_PRODUCT_ORDER, DELETE_PRODUCT_ORDER, GET_PRODUCT_ORDER, UPDATE_PRODUCT_ORDER, UPDATE_PRODUCT_ORDER_STATUS } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PRODUCT_ORDER:
            return { ...state, list: payload };

        case ADD_PRODUCT_ORDER:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case UPDATE_PRODUCT_ORDER:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };
        case UPDATE_PRODUCT_ORDER_STATUS:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case DELETE_PRODUCT_ORDER:
            return {
                ...state,
                list: state.list.filter((item) => item._id !== payload._id),
            };

        default:
            return state;
    }
};

export default reducer;
