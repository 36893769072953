import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
} from "antd";
import {
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import ProductsForm from "./NotifyForm.js";
import moment from "moment-timezone";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import {  deleteNotify, getNotify} from "../../store/actions/notify.js";

const { Title } = Typography;

const Notify = () => {
  document.title = "Notify";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);


  const dispatch = useDispatch();
  const notify = useSelector((state) => state.notify.list);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getNotify());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);


  // const handleCreate = async (values) => {
  //   const formData = new FormData();
  
  //   Object.keys(values).forEach(key => {
  //     if (values[key] !== undefined && values[key] !== null) {
  //       formData.append(key, values[key]);
  //     }
  //   });
  
  //   try {
  //     if (selectedRecord) {
  //       formData.append('id', selectedRecord._id);
  //       await dispatch(updateNotify(formData));
  //       await dispatch(getNotify());
  //     } else {
  //       await dispatch(addNotify(formData));
  //       await dispatch(getNotify());
  //     }
  //     setIsModalVisible(false);
  //     return { success: true, message: 'Coupon operation completed successfully.' };
  //   } catch (error) {
  //     console.error('Error in coupon operation:', error);
  //     return { success: false, message: 'An error occurred during the coupon operation.' };
  //   }
  // };


  const handleDelete = async (id) => {
    await dispatch(deleteNotify(id));
  };

  let columns = [
    {
      title: "series Id",
      key: "seriesId",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{record.seriesId}</span>
        </div>
      ),
    },
    {
      title: "Series Name",
      dataIndex: "seriesName",
      key: "seriesName",
      sorter: (a, b) => a.seriesName.localeCompare(b.seriesName),
      sortDirections: ["ascend", "descend"],
    },
    // {
    //   title: "Image",
    //   key: "Image",
    //   render: (text, record) => (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       {record.images && record.images.length > 0 ? (
    //         <img
    //           src={record.images[0].src} // Displaying the first image from the images array
    //           alt={record.product.name}
    //           style={{ width: 50, height: 50, objectFit: "cover", marginRight: 10 }}
    //         />
    //       ) : (
    //         <div style={{ width: 50, height: 50, backgroundColor: "#f0f0f0", marginRight: 10 }} />
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status ? "Mail Send Pending" : "Mail Sent"),
      sorter: (a, b) => (a.status === b.status ? 0 : a.status ? 1 : -1),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    }
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         type="link"
    //         onClick={() => {
    //           setSelectedRecord(record);
    //           setIsModalVisible(true);
    //         }}
    //       >
    //         Edit
    //       </Button>
    //       <Popconfirm
    //         title="Are you sure you want to delete this record?"
    //         onConfirm={() => {
    //           handleDelete(record._id)
    //         }}
    //       >
    //         <Button type="link" danger>
    //           Delete
    //         </Button>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];
  
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Notifications</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Notifications
              </Title>
            </Col>
            {/* <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Notification
              </Button>
            </Col> */}
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={notify.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      {/* <ProductsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      /> */}
    </div>
  );
};

export default Notify;
