import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Select, Pagination, Col, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getBranch } from "../../store/actions/branch";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const DataTable = ({ dataSource, columns, sumColumns = [], ...rest }) => {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState(dataSource);
  const [currentRecords, setCurrentRecords] = useState(dataSource);
  const [columnTotals, setColumnTotals] = useState({});
  const [statusFilter, setStatusFilter] = useState("All");
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [branchFilter, setBranchFilter] = useState("All");
  const [soldFilter, setSoldFilter] = useState("0");
  const dispatch = useDispatch();
  const branchList = useSelector((state) => state.branch.list);
  console.log("Branch List", branchList);

  useEffect(() => {
    dispatch(getBranch());
  }, [dispatch]);

  const calculateColumnTotals = (records) => {
    const totals = {};
    if (records) {
      sumColumns.forEach((columnName) => {
        const total = records.reduce(
          (sum, record) => sum + (parseFloat(record[columnName]) || 0),
          0
        );
        totals[columnName] = total.toFixed(2);
      });
    }

    setColumnTotals(totals);
  };

  const getPagination = useCallback(
    (currentPage, pageSize, filteredRecords) => {
      const pageStart = (currentPage - 1) * pageSize + 1;
      const pageEnd =
        currentPage * pageSize > filteredRecords.length
          ? filteredRecords.length
          : currentPage * pageSize;

      return { pageStart: filteredRecords.length > 0 ? pageStart : 0, pageEnd };
    },
    []
  );

  const [pageData, setPageData] = useState(
    getPagination(currentPage, pageSize, dataSource)
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const searchTableData = (searchString, data) => {
    const filteredData = data.filter((item) =>
      Object.values(item).some((value) => {
        return value
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchString?.toString()?.toLowerCase());
      })
    );
    return filteredData;
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
    setCurrentPage(1);
  };

  const filterByStatus = (data, status) => {
    if (status === "Active") {
      return data.filter((item) => item.status === 1);
    }
    if (status === "Inactive") {
      return data.filter((item) => item.status === 0);
    }
    return data; // 'All' status
  };

  const filterByCategory = (data, category) => {
    if (category === "All") {
      return data;
    }
    return data.filter((item) => item.category === category);
  };

  const filterBySoldStatus = (data, soldStatus) => {
    if (soldStatus === "All") {
      return data; // No filtering
    }
    if (soldStatus === "0") {
      return data.filter((item) => item.isSold === 0);
    }
    if (soldStatus === "1") {
      return data.filter((item) => item.isSold === 1);
    }
    return data; // 'All' sold status
  };

  // Filter by branch logic
  const filterByBranch = (data, branchId) => {
    if (branchId === "All") {
      return data;
    }
      // If a specific branch is selected, filter by branchId
  if (branchId === "No Branch") {
    // Filter records that don't have any branch assigned
    return data.filter((item) => !item.branchId); // Items without a branchId
  }
    return data.filter((item) => item.branchId === branchId); // Filtering by branchId
  };

  useEffect(() => {
    if (!dataSource || dataSource.length === 0) {
      return;
    }
    let filteredRecordsFromSearch = searchTableData(searchText, dataSource);
    filteredRecordsFromSearch = filterByStatus(
      filteredRecordsFromSearch,
      statusFilter
    );
    filteredRecordsFromSearch = filterByCategory(
      filteredRecordsFromSearch,
      categoryFilter
    );
    filteredRecordsFromSearch = filterBySoldStatus(
      filteredRecordsFromSearch,
      soldFilter
    );
    filteredRecordsFromSearch = filterByBranch(
      filteredRecordsFromSearch,
      branchFilter
    );
    const currentRecords = filteredRecordsFromSearch.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    setCurrentRecords(currentRecords);
    calculateColumnTotals(currentRecords);
    setFilteredData(filteredRecordsFromSearch);
    const pageData = getPagination(
      currentPage,
      pageSize,
      filteredRecordsFromSearch
    );
    setPageData(pageData);
  }, [
    dataSource,
    searchText,
    currentPage,
    pageSize,
    statusFilter,
    categoryFilter,
    soldFilter,
    branchFilter
  ]);

  const getCsvData = () => {
    const csvData = currentRecords.map((item) => {
      const row = {};
      columns.forEach((column) => {
        row[column.dataIndex] = item[column.dataIndex];
      });
      return row;
    });
    return csvData;
  };

  // Add a function to export data to Excel format

  // Add a function to export data to PDF format
  const exportToPdf = () => {
    const doc = new jsPDF();
    const tableData = [];
    columns.forEach((column) => {
      tableData.push(column.title);
    });
    currentRecords.forEach((item) => {
      const rowData = [];
      columns.forEach((column) => {
        rowData.push(item[column.dataIndex]);
      });
      tableData.push(rowData);
    });

    doc.autoTable({
      head: [tableData.slice(0, columns.length)],
      body: tableData.slice(columns.length),
    });
    const date = new Date();
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    doc.save(`${formattedDate}.pdf`);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      currentRecords.map((record) =>
        columns.reduce((obj, column) => {
          obj[column.title] = record[column.dataIndex];
          return obj;
        }, {})
      )
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const date = new Date();
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    FileSaver.saveAs(data, `${formattedDate}.xlsx`);
  };

  const updatedColumns = columns.map((column) => ({
    ...column,
    title:
      sumColumns.includes(column.dataIndex) && columnTotals[column.dataIndex]
        ? `${column.title} (Total: ${columnTotals[column.dataIndex]})`
        : column.title,
  }));

  return (
    <div {...rest}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={18} lg={18} xl={18} xxl={18}>
          <div style={{ margin: 5 }}>
            <span>Show</span>
            <Select
              defaultValue={10}
              onChange={(value) =>
                setPageSize(value === 0 ? dataSource.length : value)
              }
              style={{ margin: "0 5px" }}
            >
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
              <Option value={30}>30</Option>
              <Option value={40}>40</Option>
              <Option value={0}>All</Option>
            </Select>
            <span>entries</span>
          </div>
        </Col>

        <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            suffix={<SearchOutlined />}
            style={{ width: "100%", float: "right" }}
          />
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Select
            placeholder="Select a Category"
            value={categoryFilter}
            onChange={(value) => setCategoryFilter(value)}
            style={{ width: "100%" }}
          >
            <Option value="All">All</Option>
            <Option value="Mobile">Mobile</Option>
            <Option value="Tablet">Tablet</Option>
            <Option value="Watch">Watch</Option>
            <Option value="Airpod">Airpod</Option>
            <Option value="Accessories">Accessories</Option>
          </Select>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Select
            placeholder="Select a Sold Status"
            value={soldFilter}
            onChange={(value) => setSoldFilter(value)}
            style={{ width: "100%" }}
          >
            <Option value="0">Unsold</Option>
            <Option value="1">Sold</Option>
            <Option value="All">ALL</Option>
          </Select>
        </Col>
        {/* New branch filter dropdown */}
        <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Select
            placeholder="Select a Branch"
            value={branchFilter}
            onChange={(value) => setBranchFilter(value)}
            style={{ width: "100%" }}
          >
            <Option value="All">All</Option>
            <Option value="No Branch">No Branch</Option>
            {/* Dynamically populate branches */}
            {branchList.map((branch) => (
              <Option key={branch._id} value={branch._id}>
                {branch.name}
              </Option>
            ))}
          </Select>
        </Col>

        <Col xs={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Button
                type="primary"
                style={{ marginRight: "16px", background: "#722ed1" }}
                onClick={() => handleStatusFilterChange("All")}
              >
                All
              </Button>
              <Button
                type="primary"
                success
                style={{ marginRight: "16px", background: "#52c41a" }}
                onClick={() => handleStatusFilterChange("Active")}
              >
                Active
              </Button>
              <Button
                type="primary"
                style={{ marginRight: "16px", background: "#eb2f96" }}
                onClick={() => handleStatusFilterChange("Inactive")}
              >
                Inactive
              </Button>
            </div>
            <div>
              <CSVLink data={getCsvData()} filename={"data.csv"}>
                <Button type="primary" style={{ marginRight: "16px" }}>
                  CSV
                </Button>
              </CSVLink>
              <Button
                type="primary"
                onClick={exportToExcel}
                style={{ marginRight: "16px" }}
              >
                Excel
              </Button>
              <Button type="primary" onClick={exportToPdf}>
                PDF
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          className="gutter-row"
          span={24}
          style={{ overflow: "auto", marginTop: "10px" }}
        >
          <Table
            dataSource={currentRecords}
            columns={updatedColumns}
            pagination={false}
            rowKey={(record) => record.key}
            exportable
            exportableProps={{
              showColumnPicker: true,
            }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <span
            style={{ margin: 5 }}
          >{`Showing ${pageData?.pageStart}-${pageData?.pageEnd} of ${filteredData.length} entries`}</span>
        </Col>
        <Col className="gutter-row" span={12}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={filteredData.length}
            onChange={handlePageChange}
            // showTotal={(total) => `Total ${total} entries`}
            showSizeChanger={false}
            style={{ margin: 5, float: "right" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DataTable;
