import Swal from "sweetalert2";
import axios from "../../axios";
import { ADD_CITY, DELETE_CITY, GET_CITIES, UPDATE_CITY, GET_ACTIVE_CITIES } from "../types";
import { setAlert } from "./alert";

export const addCity = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("city/add", formData);
    if (res.data.code === 1) {
      dispatch({ type: ADD_CITY, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
    err.response?.data?.message || "An unexpected error occurred";
    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
};
export const getCity = () => async (dispatch) => {
  try {
    const res = await axios.get("city/get?status=1");
    if (res.data.code === 1) {
      dispatch({ type: GET_CITIES, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getActiveCity = () => async (dispatch) => {
  try {
    const res = await axios.get("city/get");
    if (res.data.code === 1) {
      dispatch({ type: GET_ACTIVE_CITIES, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateCity = (formData) => async (dispatch) => {
  try {
    const updatedFormData = {
      id: formData._id,
      ...(formData.name && { name: formData.name }),
      ...(formData.stateId && { stateId: formData.stateId }),
      ...(formData.status !== undefined && { status: formData.status }),
    };
    
    const res = await axios.post("city/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_CITY, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'warning!',
        text: res.data.message,
        icon: 'warning',
    });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteCity = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`city/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_CITY, payload: { id } });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
