import { GET_PRODUCT_ORDER, ADD_PRODUCT_ORDER, UPDATE_PRODUCT_ORDER,UPDATE_PRODUCT_ORDER_STATUS, DELETE_PRODUCT_ORDER } from "../types";
import {setAlert} from "./alert";
import axios from "../../axios";
import Swal from 'sweetalert2';

export const getProductOrders = () => async (dispatch) => {
    try {
        const res = await axios.get("order/get");
        if (res.data.code === 1) {
            dispatch({type: GET_PRODUCT_ORDER, payload: res.data.data});
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addProductOrder = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("order/add", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        if (res.data.code === 1) {
            dispatch({type: ADD_PRODUCT_ORDER, payload: res.data.data});
            dispatch(setAlert(res.data.message, 'success'));
            Swal.fire({
              title: 'Success!',
              text: res.data.message,
              icon: 'success',
            });
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const updateProductOrder = (formData) => async (dispatch) => {
    try {
      const res = await axios.post("order/update", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (res.data.code === 1) {
        dispatch({type: UPDATE_PRODUCT_ORDER, payload: res.data.data});
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
        });
        dispatch(setAlert(res.data.message, 'success'));
      } else {
        dispatch(setAlert(res.data.message, "warning"));
      }
    } catch (err) {
      dispatch(setAlert(err.message, 'danger'));
    }
  };


  export const updateProductOrderStatus = (orderId, newStatus) => async (dispatch) => {
    try {
      const res = await axios.post("order/update", {
        id: orderId,
        orderStatus: newStatus
      });
  
      if (res.data.code === 1) {
        dispatch({ type: UPDATE_PRODUCT_ORDER_STATUS, payload: res.data.data });
  
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
        });
  
        dispatch(setAlert(res.data.message, 'success'));
      } else {
        dispatch(setAlert(res.data.message, 'warning'));
      }
    } catch (err) {
      console.error('Error updating product order status:', err);
      dispatch(setAlert(err.message, 'danger'));
    }
  };
export const deleteProductOrder = (_id) => async (dispatch) => {
    try {
        if (!_id) {
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.post(`order/delete/${_id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_PRODUCT_ORDER, payload: {_id}});
            dispatch(setAlert(res.data.message, 'success'));
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};





