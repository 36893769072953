import React, { useEffect, useState } from "react";
import UsePanel from "./UserPanel";
import OrderStatus from "./OrderStatus";
import Notifications from "./Notifications";
import SocialSource from "./SocialSource";
import OverView from "./OverView";
import RevenueByLocation from "./RevenueByLocation";
import LatestTransation from "./LatestTransation";

import { Row, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getProductOrders } from "../../store/actions/productorder";
import { getCustomers } from "../../store/actions/customers";
import { Spin } from "antd";
import NewCustomersTable from "./NewCustomersTable";
import { getProducts } from "../../store/actions/products";
import CustomerPanel from "./CustomerPanel";
import ProductsPanel from "./ProductsPanel";
import OrdersPanel from "./OrdersPanel";

const Dashboard = () => {
  document.title = "Dashboard | 09MobileGallery - Admin Dashboard ";
  const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
  const order = useSelector((state) => state.productorder.list);
  const customer = useSelector((state) => state.customers.list);
  const products = useSelector((state) => state.products.list);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getProductOrders());
        await dispatch(getCustomers());
        await dispatch(getProducts());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="09MobileGallery" breadcrumbItem="Dashboard" />
          {isLoading ? (
            <div className="text-center">
              <Spin size="large" />
            </div>
          ) : (
            <>
          {/* User Panel Charts */}
          <UsePanel allcust={customer} allorders={order} allprod={products} />

          <Row>
            {/* Overview Chart */}
            <OverView order={order} />
            {/* Social Source Chart */}
            <SocialSource />
          </Row>

          {/* Latest Transaction Table */}
          <LatestTransation order={order} />
          
          <NewCustomersTable customer={customer} />
          
          {/* <LatestTransation customer={customer} /> */}
          <CustomerPanel />
          
          <ProductsPanel  />

          <OrdersPanel />
          <Row>
            {/* Order Stats */}
            {/* <OrderStatus /> */}
            {/* Notifications */}
            {/* <Notifications /> */}
            {/* Revenue by Location Vector Map */}
            {/* <RevenueByLocation /> */}
          </Row>
          </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
