import { ADD_FAQ, DELETE_FAQ, GET_FAQ, UPDATE_FAQ } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_FAQ:
            return { ...state, list: payload };

        case UPDATE_FAQ:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case ADD_FAQ:
                return {
                    ...state,
                    list: [payload, ...state.list],
                };

        case DELETE_FAQ:
            return {
                ...state,
                list: state.list.filter((item) => item._id !== payload._id),
            };

        default:
            return state;
    }
};

export default reducer;
