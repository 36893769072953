import { ADD_STATE, DELETE_STATE, GET_STATES, UPDATE_STATE } from "../types";


const initialState = {list: [], single: null, cities: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_STATES:
            return {...state, list: payload};

        case UPDATE_STATE:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case ADD_STATE:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case DELETE_STATE:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== payload.id),
            };

        default:
            return state;
    }
};

export default reducer;
