import React, { useState, useCallback } from 'react';
import { Input, DatePicker, Menu, Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { dateSorter, dateTimeSort } from "../../utils/functions";

const { RangePicker } = DatePicker;

// Custom debounce function
const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

// StringFilterDropdown component
const StringFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => {
    const [searchText, setSearchText] = useState(selectedKeys[0]);

    const debouncedSearch = useCallback(
        debounce((value) => {
            setSelectedKeys(value ? [value] : []);
            confirm({ closeDropdown: false });
        }, 500),
        [setSelectedKeys, confirm]
    );

    return (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Search ${column.title}`}
                value={searchText}
                onChange={(e) => {
                    const value = e.target.value;
                    setSearchText(value);
                    debouncedSearch(value);
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button onClick={() => {
                clearFilters();
                setSearchText('');
                confirm();
            }} size="small" style={{ width: 90 }}>
                Reset
            </Button>
        </div>
    );
};

export const enhanceColumnsWithFilters = (columns) => {
    return columns.map(column => {
        if (column.key === 'action' || column.key === 'srno') {
            return column;
        }

        let enhancedColumn = { ...column };

        if (column.key === 'status') {
            enhancedColumn.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Menu
                        onClick={({ key }) => {
                            setSelectedKeys(key === 'ALL' ? [] : [key]);
                            confirm();
                        }}
                        selectedKeys={selectedKeys}
                    >
                        <Menu.Item key="ALL">All</Menu.Item>
                        <Menu.Item key="1">Active</Menu.Item>
                        <Menu.Item key="0">Inactive</Menu.Item>
                    </Menu>
                    <Button onClick={() => {
                        clearFilters();
                        confirm();
                    }} size="small" style={{ width: '100%', marginTop: 8 }}>Reset</Button>
                </div>
            );
            enhancedColumn.onFilter = (value, record) => {
                if (value === 'ALL' || value === undefined) return true;
                return record.status.toString() === value;
            };
        } else if (column.dataIndex === 'createdAt' || isDateTimeColumn(column)) {
            enhancedColumn.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <RangePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={(dates) => {
                            setSelectedKeys(dates ? dates : []);
                            confirm({ closeDropdown: false });
                        }}
                        onOk={() => confirm()}
                        value={selectedKeys.length ? [moment(selectedKeys[0]), moment(selectedKeys[1])] : []}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Button onClick={() => {
                        clearFilters();
                        confirm();
                    }} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            );
            enhancedColumn.onFilter = (value, record) => {
                if (!value || value.length !== 2) return true;
                const recordDate = moment(record[column.dataIndex]);
                return recordDate.isBetween(value[0], value[1], 'second', '[]');
            };
        } else if (isDateColumn(column)) {
            enhancedColumn.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <RangePicker
                        format="YYYY-MM-DD"
                        onChange={(dates) => {
                            setSelectedKeys(dates ? dates : []);
                            confirm({ closeDropdown: false });
                        }}
                        onOk={() => confirm()}
                        value={selectedKeys.length ? [moment(selectedKeys[0]), moment(selectedKeys[1])] : []}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Button onClick={() => {
                        clearFilters();
                        confirm();
                    }} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            );
            enhancedColumn.onFilter = (value, record) => {
                if (!value || value.length !== 2) return true;
                const recordDate = moment(record[column.dataIndex]);
                return recordDate.isBetween(value[0], value[1], 'day', '[]');
            };
        } else {
            enhancedColumn.filterDropdown = (props) => <StringFilterDropdown {...props} column={column} />;
            enhancedColumn.onFilter = (value, record) =>
                record[column.dataIndex]
                    ?.toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
        }

        enhancedColumn.filterIcon = filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        );

        return enhancedColumn;
    });
};

function isDateColumn(column) {
    return column.sorter === dateSorter ||
        /date/i.test(column.dataIndex) ||
        /date/i.test(column.key);
}

function isDateTimeColumn(column) {
    return column.dataIndex === 'CreatedAt' ||
        column.sorter === dateTimeSort ||
        /datetime/i.test(column.dataIndex) ||
        /datetime/i.test(column.key);
}