import {
  DELETE_PRODUCTS,
  GET_PRODUCTS,
  UPDATE_PRODUCTS,
  DELETE_PRODUCTS_IMAGE,
  ADD_PRODUCTS,
  GET_DASH_PRODUCTS,
  GET_TOP_BRAND
} from "../types";
import { setAlert } from "./alert";
import axios from "../../axios";
import Swal from "sweetalert2";

export const getProducts = (status) => async (dispatch) => {
  try {
    const url = status !== undefined ? `prod/get?status=${status}` : "prod/get";
    const res = await axios.get(url);
    // const res = await axios.get("prod/get");
    if (res.data.code === 1) {
      dispatch({ type: GET_PRODUCTS, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getDashboardProducts = (status, fromDate, toDate) => async (dispatch) => {
  try {
      const url = `prod/get?status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
      const res = await axios.get(url);
      if (res.data.code === 1) {
          dispatch({ type: GET_DASH_PRODUCTS, payload: res.data.data });
      }
  } catch (err) {
      console.log(err);
      const errorMessage = err.response?.data?.message || 'An unexpected error occurred';
      Swal.fire({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
      });
  }
};

export const addProducts = (form, formData, setSelectedBrand, setSelectedModel) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axios.post("prod/add", formData, config);

    console.log("add prod formData :", formData);

    if (res.data.code === 1) {
      dispatch({ type: ADD_PRODUCTS, payload: res.data.data });
      dispatch(setAlert(res.data.message, "success"));
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      })
      form.resetFields();
      setSelectedBrand(null);
      setSelectedModel(null);
      formData.name = "";
      await dispatch(getProducts())
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: "Warning!",
        text: res.data.message,
        icon: "warning",
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
      err.response?.data?.message || "An unexpected error occurred";

    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
};

// export const addProducts = (formData, quantity) => async (dispatch) => {
//     try {
//         console.log("quantity :", quantity);

//         for (let i = 0; i < quantity; i++) {
//             const res = await axios.post("prod/add", formData, {
//                 headers: {
//                   'Content-Type': 'multipart/form-data'
//                 }
//             });

//             if (res.data.code === 1) {
//                 dispatch({ type: ADD_PRODUCTS, payload: res.data.data });
//                 dispatch(setAlert(res.data.message, 'success'));
//             } else {
//                 dispatch(setAlert(res.data.message, "warning"));
//             }
//         }
//     } catch (err) {
//         dispatch(setAlert(err.message, "danger"));
//     }
// };

// export const addProducts = (formData) => async (dispatch) => {
//     try {
//         const res = await axios.post("prod/add", formData, {
//             headers: {
//               'Content-Type': 'multipart/form-data'
//             }
//           });
//         if (res.data.code === 1) {
//             dispatch({type: ADD_PRODUCTS, payload: res.data.data});
//             dispatch(setAlert(res.data.message, 'success'));
//         } else {
//             dispatch(setAlert(res.data.message, "warning"));
//         }
//     } catch (err) {
//         dispatch(setAlert(err.message, "danger"));
//     }
// };

export const updateProducts = (formData, form,  setSelectedBrand, setSelectedModel) => async (dispatch) => {
  try {
    const res = await axios.post("prod/update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
console.log("formData :", formData);

    if (res.data.code === 1) {
      dispatch({ type: UPDATE_PRODUCTS, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"))
      form.resetFields();
      setSelectedBrand(null);
      setSelectedModel(null);
      formData.name = "";
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      return Swal.fire({
        title: "Warning!",
        text: res.data.message,
        icon: "warning",
      });
    }
    if (res.status === 400) {
      const errorMessage =
      res?.response.data?.message;

    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));

    const errorMessage =
      err;
      console.log("error messeage :", errorMessage);

    // Swal.fire({
    //   title: "Error!",
    //   text: errorMessage,
    //   icon: "error",
    // });
  }
};

// export const updateProducts = (formData) => async (dispatch) => {
//     try {
//         // const updatedFormData = { ...formData, id: formData._id };
//         const updatedFormData = {
//             id: formData._id,
//             ...(formData.name && { name: formData.name }),
//         };
//         console.log("updatedFormData :", updatedFormData);
//         const res = await axios.post("prod/update", updatedFormData);

//         if (res.data.code === 1) {
//             dispatch({type: UPDATE_PRODUCTS, payload: res.data.data});
//             Swal.fire({
//                 title: 'Success!',
//                 text: res.data.message,
//                 icon: 'success',
//             });
//             dispatch(setAlert(res.data.message, 'success'));
//         } else {
//             dispatch(setAlert(res.data.message, "warning"));
//         }
//     } catch (err) {
//         dispatch(setAlert(err.message, 'danger'));
//     }
// };

export const deleteProducts = (_id) => async (dispatch) => {
  try {
    if (!_id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`prod/delete/${_id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_PRODUCTS, payload: { _id } });
      dispatch(setAlert(res.data.message, "success"));
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteImage = (_id) => async (dispatch) => {
  try {
    if (!_id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`img/delete/${_id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_PRODUCTS_IMAGE, payload: { _id } });
      dispatch(setAlert(res.data.message, "success"));
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
export const getTopBrand = (month) => async (dispatch) => {
  try {
    const res = await axios.get(`/prod/get-top-brands?month=${month}`);
    if (res.data) {
      dispatch({ type: GET_TOP_BRAND, payload: res.data });
    }
  } catch (err) {
    console.log(err);
  }
};