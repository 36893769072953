import React, { useEffect } from 'react';
import { Form, Input, Modal, Select} from 'antd';

const TicketReasonForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(record);
  }, [record, form]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      visible={visible}
      title={"Update Ticket Reason"}
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="product_form"
        initialValues={record}
      >
        <Form.Item
          name="name"
          label="Reason"
          rules={[{ required: true, message: 'Please enter the Reason!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Please select a category!' }]}
        >
          <Select>
            <Select.Option value="PRODUCT">PRODUCT</Select.Option>
            <Select.Option value="DELIVERY">DELIVERY</Select.Option>
            <Select.Option value="SERVICE">SERVICE</Select.Option>
            <Select.Option value="OTHER">OTHER</Select.Option>
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default TicketReasonForm;