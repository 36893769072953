import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customFilterDropdown, stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Switch,
} from "antd";
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import BrandsForm from "./BranchForm.js";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import moment from "moment-timezone";
import { addBranch, deleteBranch, getBranch, updateBranch } from "../../store/actions/branch.js";


const { Title } = Typography;

const Branch = () => {
  document.title = "Branch";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dispatch = useDispatch();
  const branch = useSelector((state) => state.branch.list);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getBranch());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);

  const handleCreate = async (values) => {
    const formData = new FormData();
    if (selectedRecord) {
      formData.append('id', selectedRecord._id);      
      await dispatch(updateBranch({ ...selectedRecord, ...values }));
      await dispatch(getBranch())
    } else {
      await dispatch(addBranch(values));
      await dispatch(getBranch())
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteBranch(id));
    await dispatch(getBranch());
  };

  const handleChangeStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    const updatedData = { _id: id, status: newStatus }; // Create an object for update
    await dispatch(updateBranch(updatedData));
    await dispatch(getBranch());
  };

  const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
      sortDirections: ["ascend", "descend"],
      filterDropdown: customFilterDropdown,
      onFilter: (value, record) => record.name.includes(value),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: stringSorter("address"),
      sortDirections: ["ascend", "descend"],
      filterDropdown: customFilterDropdown,
      onFilter: (value, record) => record.address.includes(value),
    },
    {
      title: "Pincode",
      dataIndex: "pinCode",
      key: "pinCode",
      sorter: stringSorter("pinCode"),
      sortDirections: ["ascend", "descend"],
      filterDropdown: customFilterDropdown,
      onFilter: (value, record) => record.pinCode.includes(value),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: stringSorter("location"),
      sortDirections: ["ascend", "descend"],
      filterDropdown: customFilterDropdown,
      render: (location, record) => (
        <a href={`${location}`} target="_blank" rel="noopener noreferrer">
          {location}
        </a>
      ),
    },    
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={text === 1}
          onChange={() => handleChangeStatus(record._id, text)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Branch</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Branch
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Branch
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={branch.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <BrandsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
    </div>
  );
};

export default Branch;