import { ADD_FAQ, DELETE_FAQ, GET_FAQ, UPDATE_FAQ } from "../types";
import {setAlert} from "./alert";
import axios from "../../axios";
import Swal from 'sweetalert2';

export const getFaqs = () => async (dispatch) => {
    try {
        const res = await axios.get("faq/get");
        if (res.data.code === 1) {
            dispatch({type: GET_FAQ, payload: res.data.data});
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addFaq = (formData) => async (dispatch) => {
    try {
      const res = await axios.post("faq/add", formData);
      console.log("faq add :", formData);
      
      if (res.data.code === 1) {
        dispatch({ type: ADD_FAQ, payload: res.data.data });
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
      });
        dispatch(setAlert(res.data.message, "success"));
      } else {
        dispatch(setAlert(res.data.message, "warning"));
      }
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };


export const updateFaqs = (formData) => async (dispatch) => {
    try {
        // const updatedFormData = { ...formData, id: formData._id };
        console.log("formdata faq:", formData);
        
        const updatedFormData = {
            id: formData._id,
            ...(formData.que && { que: formData.que }),
            ...(formData.ans && { ans: formData.ans }),
            ...(formData.url && { url: formData.url })
        };
        console.log("updatedFormData :", updatedFormData);
        const res = await axios.post("faq/update", updatedFormData);
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_FAQ, payload: res.data.data});
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
            dispatch(setAlert(res.data.message, 'success'));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

export const updateFaqStatus = (id, status) => async (dispatch) => {
    try {
        // const updatedFormData = { ...formData, id: formData._id };
        console.log("formdata updateFaqStatus:", id, status);
        
        const res = await axios.post("user/update", {id, status}, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
        
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_FAQ, payload: res.data.data});
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
            dispatch(setAlert(res.data.message, 'success'));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

export const deleteFaqs = (_id) => async (dispatch) => {
    try {
        if (!_id) {
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.post(`faq/delete/${_id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_FAQ, payload: {_id}});
            dispatch(setAlert(res.data.message, 'success'));
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};





