import { ADD_COUNTRY, DELETE_COUNTRY, GET_COUNTRYS, UPDATE_COUNTRY } from "../types";

const initialState = {list: [], single: null, cities: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_COUNTRYS:
            return {...state, list: payload};

        case UPDATE_COUNTRY:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case ADD_COUNTRY:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case DELETE_COUNTRY:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== payload.id),
            };

        default:
            return state;
    }
};

export default reducer;
