import React, { useEffect } from "react";
import { Form, Input, Modal, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const BrandsForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      _id: record?._id,
      name: record?.name,
      image: record?.image ? [{ url: record.image }] : [],
      order: record?.order || null,
    });
  }, [record, form]);

  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e?.fileList || [];
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type === "image/jpeg" || file.type === "image/png";
      if (!isImage) {
        message.error(`${file.name} is not a valid JPG/PNG file.`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Brand" : "Add Brand"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values); // Pass form values directly
          })
          .catch((info) => {
            console.log("Validation failed:", info);
          });
      }}
      width={800}
    >
      <Form form={form} layout="vertical" name="brand_form">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the brand name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="Select a single image to upload"
        >
          <Upload name="image" listType="picture" accept="image/png, image/jpeg" {...uploadProps}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[{ required: true, message: "Please enter the model order!" }]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BrandsForm;



// import React, { useEffect } from 'react';
// import { Form, Input, Modal, Upload, Button } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';

// const BrandsForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();

//   useEffect(() => {
//     form.setFieldsValue({
//       _id: record?._id,
//       name: record?.name,
//       image: record?.image ? [{ url: record.image }] : [],
//     });
//   }, [record, form]);

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     if (e && e.fileList) {
//       return e.fileList;
//     }
//     return [];
//   };
  
//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Brand" : "Add Brand"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="brand_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the brand name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="image"
//           label="Image"
//           valuePropName="fileList"
//           getValueFromEvent={normFile}
//           extra="Select a single image to upload"
//         >
//           <Upload
//             name="image"
//             listType="picture"
//             beforeUpload={() => false} // Prevent automatic upload
//             maxCount={1} // Restrict to one file
//             fileList={form.getFieldValue('image')}
//             // customRequest={({ file, onSuccess, onError }) => {
//             //   // Simulate an upload request
//             //   setTimeout(() => onSuccess("ok"), 0);
//             // }}
//           >
//             <Button icon={<UploadOutlined />}>Upload</Button>
//           </Upload>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default BrandsForm;


// import React, { useEffect } from 'react';
// import { Form, Input, Modal, InputNumber, Switch, Upload, Button } from 'antd';
// import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

// const BrandsForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();

//   useEffect(() => {
//     form.setFieldsValue({
//       name: record?.name || '',
//       image: record?.image ? [{ url: record.image }] : [], // Set image if exists
//     });
//   }, [record, form]);

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     return e?.fileList || [];
//   };

//   const handleChange = ({ fileList }) => {
//     form.setFieldsValue({ image: fileList });
//   };
//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Brand" : "Add Brand"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="product_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the product name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="image"
//           label="Image"
//           valuePropName="fileList"
//           getValueFromEvent={normFile}
//         >
//           <Upload
//             name="image"
//             listType="picture"
//             beforeUpload={() => false} 
//             onChange={handleChange}
//             customRequest={({ file, onSuccess }) => {
//               setTimeout(() => {
//                 onSuccess("ok");
//               }, 0);
//             }}
//           >
//             <Button icon={<UploadOutlined />}>Upload</Button>
//           </Upload>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default BrandsForm;