// CustomToggleSwitch.js
import React, { useState, useEffect } from "react";
import { Switch } from "antd";

const CustomToggleSwitch = ({ record, onChangeStatus }) => {
  const [isActive, setIsActive] = useState(true); // Local state for active status

  useEffect(() => {
    if (record) {
      setIsActive(record.status === 1); // Initialize switch state based on record's status
    }
  }, [record]);

  const handleChange = (checked) => {
    setIsActive(checked);
    // Call parent function to handle status change
    onChangeStatus(record._id, checked ? 1 : 0); // Use 1 for active, 0 for inactive
  };

  return (
    <div>
      <Switch
        checked={isActive}
        onChange={handleChange}
      />
    </div>
  );
};

export default CustomToggleSwitch;
