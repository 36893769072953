import Swal from "sweetalert2";
import axios from "../../axios";
import {
  ADD_TICKET_REASON,
  DELETE_TICKET_REASON,
  GET_ACTIVE_TICKET_REASON,
  GET_TICKET_REASON,
  UPDATE_TICKET_REASON
} from "../types";
import { setAlert } from "./alert";

export const addTicketReason = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("ticket-reason/add", formData);
    if (res.data.code === 1) {
      dispatch({ type: ADD_TICKET_REASON, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
    err.response?.data?.message || "An unexpected error occurred";
    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
};
export const getTicketReasons = () => async (dispatch) => {
  try {
    const res = await axios.get("ticket-reason/get");
    console.log("ticket-reason :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_TICKET_REASON, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getActiveTicketReason = () => async (dispatch) => {
  try {
    const res = await axios.get("ticket-reason/get?status=1");
    if (res.data.code === 1) {
      dispatch({ type: GET_ACTIVE_TICKET_REASON, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateTicketReason = (formData) => async (dispatch) => {
  try {
    const updatedFormData = {
      id: formData._id,
      ...(formData.name && { name: formData.name }),
      ...(formData.category && { category: formData.category }),
      ...(formData.status !== undefined && { status: formData.status }),
    };
    console.log("updatedFormData :", updatedFormData);

    const res = await axios.post("ticket-reason/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_TICKET_REASON, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: "warning!",
        text: res.data.message,
        icon: "warning",
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteTicketReason = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`ticket-reason/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_TICKET_REASON, payload: { id } });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
