import React, { useEffect } from 'react';
import { Form, Input, Modal} from 'antd';

const BranchForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(record);
  }, [record, form]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Branch" : "Add Branch"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="product_form"
        initialValues={record}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the product name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          // rules={[{ required: true, message: 'Please enter the product address!' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="pinCode"
          label="Pincode"
          // rules={[{ required: true, message: 'Please enter the product pinCode!' }]}
        >
          <Input type='number' minLength={6} maxLength={6} />
        </Form.Item>
        <Form.Item
          name="location"
          label="Location"
          // rules={[{ required: true, message: 'Please enter the product location!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BranchForm;
//   const [form] = Form.useForm();

//   // Set initial values when the record changes
//   React.useEffect(() => {
//     form.setFieldsValue(record);
//   }, [record, form]);

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Product" : "Add Product"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="product_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the product name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="brandName"
//           label="Brand"
//           rules={[{ required: true, message: 'Please enter the brand name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="modelName"
//           label="Model"
//           rules={[{ required: true, message: 'Please enter the model name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="seriesName"
//           label="Series"
//           rules={[{ required: true, message: 'Please enter the series name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="color"
//           label="Color"
//           rules={[{ required: true, message: 'Please enter the color!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="price"
//           label="Price"
//           rules={[{ required: true, message: 'Please enter the price!' }]}
//         >
//           <InputNumber min={0} style={{ width: '100%' }} />
//         </Form.Item>
//         <Form.Item
//           name="storage"
//           label="Storage"
//           rules={[{ required: true, message: 'Please enter the storage!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="network"
//           label="Network"
//           rules={[{ required: true, message: 'Please enter the network!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="osNo"
//           label="OS"
//           rules={[{ required: true, message: 'Please enter the OS!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="inStock"
//           label="In Stock"
//           valuePropName="checked"
//         >
//           <Input type="checkbox" />
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default ProductsForm;