import React, { useEffect } from 'react';
import { Form, Input, Modal, InputNumber, Upload, Select, Switch, Row, Col, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getModels } from '../../store/actions/model';
import { getBrands } from '../../store/actions/brand';
import { getSeries } from '../../store/actions/series';

const ProductsUpdateForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const BrandList = useSelector((state) => state.brand.list);
  const ModelList = useSelector((state) => state.model.list);
  const SeriesList = useSelector((state) => state.series.list);

  useEffect(() => {
    // Fetch brands on component mount
    dispatch(getModels());
    dispatch(getBrands());
    dispatch(getSeries());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(record);
  }, [record, form]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpeg';
      if (!isPNG || !isJPG) {
        message.error(`${file.name} is not a png/jpeg file`);
      }
      return isPNG || isJPG || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Product" : "Add Product"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="product_form"
        initialValues={record}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter the product name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="brandId"
              label="Brand"
              rules={[{ required: true, message: 'Please select a brand!' }]}
            >
              <Select placeholder="Select a brand" onChange={handleChange}>
                {BrandList.map((brand) => (
                  <Select.Option key={brand._id} value={brand._id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="modelId"
              label="Model"
              rules={[{ required: true, message: 'Please select a model!' }]}
            >
              <Select placeholder="Select a model" onChange={handleChange}>
                {ModelList.map((model) => (
                  <Select.Option key={model._id} value={model._id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="seriesId"
              label="Series"
              rules={[{ required: true, message: 'Please select a series!' }]}
            >
              <Select placeholder="Select a series" onChange={handleChange}>
                {SeriesList.map((series) => (
                  <Select.Option key={series._id} value={series._id}>
                    {series.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="color"
              label="Color"
              rules={[{ required: true, message: 'Please enter the color!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="price"
              label="Price"
              rules={[{ required: true, message: 'Please enter the price!' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="storage"
              label="Storage"
              rules={[{ required: true, message: 'Please enter the storage!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item name="network" label="Network">
              <Select placeholder="Select a network type">
                <Select.Option value="4g">4G</Select.Option>
                <Select.Option value="5g">5G</Select.Option>
                <Select.Option value="3g">3G</Select.Option>
                <Select.Option value="lte">LTE</Select.Option>
                {/* Add other network types if needed */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="osNo"
              label="OS"
              rules={[{ required: true, message: 'Please enter the OS!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="imei"
              label="IMEI"
              rules={[
                { required: true, message: 'Please enter the IMEI!' },
                { max: 15, message: 'IMEI must be at most 15 characters long!' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="inStock"
              label="In Stock"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="qty"
              label="Quantity"
              rules={[{ required: true, message: 'Please enter the quantity!' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="about"
              label="About"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="battery"
              label="Battery"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="guarantee"
              label="Guarantee"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="warranty"
              label="Warranty"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="ram"
              label="RAM"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="usedTime"
              label="Used Time"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="youtubeUrl"
              label="YouTube URL"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}><Form.Item
              name="category"
              label="Category"
            >
              <Select placeholder="Select a Category type">
        <Select.Option value="Mobile">Mobile</Select.Option>
        <Select.Option value="Tablet">Tablet</Select.Option>
        <Select.Option value="Watch">Watch</Select.Option>
        <Select.Option value="Airpod">Airpod</Select.Option>
        <Select.Option value="Accessories">Accessories</Select.Option>
      </Select>
            </Form.Item></Col>
          <Col span={24}>
            <Form.Item
              name="images"
              label="Images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload {...props} listType="picture-card" accept="image/png, image/jpeg" multiple>
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductsUpdateForm;



// import React, { useEffect } from 'react';
// import { Form, Input, Modal, InputNumber, Upload, Select } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
// import { useDispatch, useSelector } from 'react-redux';
// import { getModels } from '../../store/actions/model';
// import { getBrands } from '../../store/actions/brand';
// import { getSeries } from '../../store/actions/series';

// const ProductsUpdateForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   const BrandList = useSelector((state) => state.brand.list);
//   const ModelList = useSelector((state) => state.model.list);
//   const SeriesList = useSelector((state) => state.series.list);

//   useEffect(() => {
//     // Fetch brands on component mount
//     dispatch(getModels());
//     dispatch(getBrands());
//     dispatch(getSeries());
//   }, [dispatch]);

//   useEffect(() => {
//     form.setFieldsValue(record);
//   }, [record, form]);

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     return e?.fileList;
//   };

//   const handleChange = (value) => {
//     console.log(`selected ${value}`);
//   };


//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Product" : "Add Product"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       // onOk={handleSubmit}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="product_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the product name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="brandId"
//           label="Brand"
//           rules={[{ required: true, message: 'Please select a brand!' }]}
//         >
//           <Select placeholder="Select a brand" onChange={handleChange}>
//             {BrandList.map((brand) => (
//               <Select.Option key={brand._id} value={brand._id}>
//                 {brand.name}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//         <Form.Item
//           name="modelId"
//           label="Model"
//           rules={[{ required: true, message: 'Please select a model!' }]}
//         >
//           <Select placeholder="Select a model" onChange={handleChange}>
//             {ModelList.map((model) => (
//               <Select.Option key={model._id} value={model._id}>
//                 {model.name}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//         <Form.Item
//           name="seriesId"
//           label="Series"
//           rules={[{ required: true, message: 'Please select a series!' }]}
//         >
//           <Select placeholder="Select a series" onChange={handleChange}>
//             {SeriesList.map((series) => (
//               <Select.Option key={series._id} value={series._id}>
//                 {series.name}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//         <Form.Item
//           name="color"
//           label="Color"
//           rules={[{ required: true, message: 'Please enter the color!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="price"
//           label="Price"
//           rules={[{ required: true, message: 'Please enter the price!' }]}
//         >
//           <InputNumber min={0} style={{ width: '100%' }} />
//         </Form.Item>
//         <Form.Item
//           name="storage"
//           label="Storage"
//           rules={[{ required: true, message: 'Please enter the storage!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="network"
//           label="Network"
//           rules={[{ required: true, message: 'Please enter the network!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="osNo"
//           label="OS"
//           rules={[{ required: true, message: 'Please enter the OS!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="imei"
//           label="IMEI"
//           rules={[{ required: true, message: 'Please enter the IMEI!' },{ max: 15, message: 'IMEI must be at most 15 characters long!' }]}
//         >
//           <Input />
//         </Form.Item>
//         {/* <Form.Item
//           name="inStock"
//           label="In Stock"
//           valuePropName="checked"
//         >
//           <Switch />
//         </Form.Item>
//         <Form.Item
//           name="qty"
//           label="Quantity"
//           rules={[{ required: true, message: 'Please enter the quantity!' }]}
//         >
//           <InputNumber min={0} style={{ width: '100%' }} />
//         </Form.Item> */}
//         <Form.Item
//           name="about"
//           label="About"
//         >
//           <Input.TextArea />
//         </Form.Item>
//         <Form.Item
//           name="battery"
//           label="Battery"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="guarantee"
//           label="Guarantee"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="warranty"
//           label="Warranty"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="ram"
//           label="RAM"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="usedTime"
//           label="Used Time"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="youtubeUrl"
//           label="YouTube URL"
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="images"
//           label="Images"
//           valuePropName="fileList"
//           getValueFromEvent={normFile}
//         >
//           <Upload listType="picture-card" multiple>
//             <div>
//               <PlusOutlined />
//               <div style={{ marginTop: 8 }}>Upload</div>
//             </div>
//           </Upload>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default ProductsUpdateForm;
