import Swal from "sweetalert2";
import axios from "../../axios";
import { setAlert } from "./alert";
import { ADD_STATE, DELETE_STATE, GET_ACTIVE_STATES, GET_STATES, UPDATE_STATE } from "../types";

export const addState = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("state/add", formData);
    if (res.data.code === 1) {
      dispatch({ type: ADD_STATE, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
      if (res.data.code === 0) {
        console.log("here :", res.data.message);  
        Swal.fire({
          title: 'warning!',
          text: res.data.message,
          icon: 'warning',
      });
    }
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'warning!',
        text: res.data.message,
        icon: 'warning',
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
      err.response?.data?.message || "An unexpected error occurred";
    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
};
export const getStates = () => async (dispatch) => {
  try {
    const res = await axios.get("state/get");
    console.log("state :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_STATES, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getActiveStates = () => async (dispatch) => {
  try {
    const res = await axios.get("state/get");
    console.log("state :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_ACTIVE_STATES, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateState = (formData) => async (dispatch) => {
  try {

    const updatedFormData = {
      id: formData._id,
      ...(formData.name && { name: formData.name }),
      ...(formData.countryId && { countryId: formData.countryId }),
      ...(formData.status !== undefined && { status: formData.status }),
    };
    
    const res = await axios.post("state/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_STATE, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'warning!',
        text: res.data.message,
        icon: 'warning',
    });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteState = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`state/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_STATE, payload: { id } });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
