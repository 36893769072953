import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberSort, stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Switch,
} from "antd";
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import ModelsForm from "./ModelsForm.js";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import { addModel, deleteModel, getModels, updateModel } from "../../store/actions/model.js";
import { getBrands } from "../../store/actions/brand.js";
import moment from "moment-timezone";

const { Title } = Typography;

const Model = () => {
  document.title = "Models";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dispatch = useDispatch();
  const model = useSelector((state) => state.model.list);
  const brandList = useSelector((state) => state.brand.list);

  const categoryOptions = [
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Tablet', label: 'Tablet' },
    { value: 'Watch', label: 'Watch' },
    { value: 'Airpod', label: 'Airpod' },
    { value: 'Accessories', label: 'Accessories' }
  ];

  const brandMap = brandList.reduce((acc, brand) => {
    acc[brand._id] = brand.name;
    return acc;
  }, {});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getModels());
        await dispatch(getBrands());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);

  const handleCreate = async (values) => {
    try {
      if (selectedRecord) {
        await dispatch(updateModel({ ...selectedRecord, ...values }));
      } else {
        await dispatch(addModel(values));
      }
      await dispatch(getModels());
      handleCancel();
    } catch (error) {
      console.error("Error handling form submission:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteModel(id));
    await dispatch(getModels());
  };

  const handleChangeStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const updatedData = { _id: id, status: newStatus };
    await dispatch(updateModel(updatedData));
    await dispatch(getModels());
  };

  const renderCell = (text) => (text ? text : 'N/A');
  
  let columns = [
    {
      title: "Brand",
      dataIndex: "brandId",
      key: "brandId",
      sorter: stringSorter("brandId"),
      sortDirections: ["ascend", "descend"],
      render: (brandId) => brandMap[brandId] || 'Unknown',
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: stringSorter("category"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sorter: numberSort("order"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={text === 1}
          onChange={() => handleChangeStatus(record._id, text)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm> 
        </Space>
      ),
    },
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Series</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Series
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Series
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={model.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <ModelsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={handleCancel}
        categoryOptions={categoryOptions}
        record={selectedRecord}
      />
    </div>
  );
};

export default Model;



// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { stringSorter } from "../../utils/functions.js";
// import DataTable from "../../components/CustomDatatable/index.js";
// import {
//   Button,
//   Card,
//   Space,
//   Typography,
//   Breadcrumb,
//   Row,
//   Col,
//   Popconfirm,
//   Switch,
// } from "antd";
// import {
//   EditOutlined,
//   HomeOutlined,
//   PlusOutlined,
// } from "@ant-design/icons";
// import { Spinner } from "reactstrap";
// import ModelsForm from "./ModelsForm.js";
// import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
// import { addModel, deleteModel, getModels, updateModel } from "../../store/actions/model.js";
// import { getBrands } from "../../store/actions/brand.js";
// import moment from "moment-timezone";

// const { Title } = Typography;

// const Model = () => {
//   document.title = "Models";
//   const [isLoading, setIsLoading] = useState(true);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [selectedRecord, setSelectedRecord] = useState(null);

//   const dispatch = useDispatch();
//   const model = useSelector((state) => state.model.list);
//   const brandList = useSelector((state) => state.brand.list); 
  
//   // Define category options array for better maintainability
//   const categoryOptions = [
//     { value: 'Mobile', label: 'Mobile' },
//     { value: 'Tablet', label: 'Tablet' },
//     { value: 'Watch', label: 'Watch' },
//     { value: 'Airpod', label: 'Airpod' },
//     { value: 'Accessories', label: 'Accessories' }
//   ];


  

//   const brandMap = brandList.reduce((acc, brand) => {
//     acc[brand._id] = brand.name; 
//     return acc;
//   }, {});

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         await dispatch(getModels());
//         await dispatch(getBrands());
//         setIsLoading(false);
//       } catch (error) {
//         setIsLoading(false);
//       }
//     };

//     fetchProducts();
//   }, [dispatch]);


//   const handleCreate = async (values) => {
//     const formData = new FormData();
//     if (selectedRecord) {
//       formData.append('id', selectedRecord._id);      
//       await dispatch(updateModel({ ...selectedRecord, ...values }));
//       await dispatch(getModels());
//     } else {
//       await dispatch(addModel(values));
//       await dispatch(getModels());
//     }
//     // Reset modal visibility and selected record
//     setIsModalVisible(false);
//     setSelectedRecord(null); // Reset selected record
//   };
  
//   // const handleCreate = async (values) => {
//   //   const formData = new FormData();
//   //   if (selectedRecord) {
//   //     formData.append('id', selectedRecord._id);      
//   //     await dispatch(updateModel({ ...selectedRecord, ...values }));
//   //     window.location.reload();
//   //     await dispatch(getModels())
//   //   } else {
//   //     await dispatch(addModel(values));
//   //     await dispatch(getModels())
      
//   //   }
//   //   setIsModalVisible(false);
//   // };
  
//   const handleCancel = () => {
//     setIsModalVisible(false);
//     setSelectedRecord(null); // Clear selected record on cancel
//   };
  

//   const handleDelete = async (id) => {
//     await dispatch(deleteModel(id));
//     await dispatch(getModels());
//   };

//   const handleChangeStatus = async (id, currentStatus) => {
//     const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
//     const updatedData = { _id: id, status: newStatus }; // Create an object for update
    
//     await dispatch(updateModel(updatedData));
//     await dispatch(getModels());
//   };

//   const renderCell = (text) => (text ? text : 'N/A');
//   let columns = [
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//       sorter: stringSorter("name"),
//       sortDirections: ["ascend", "descend"],
//     },
//     {
//       title: "Category",
//       dataIndex: "category",
//       key: "category",
//       sorter: stringSorter("category"),
//       sortDirections: ["ascend", "descend"],
//     },
//     {
//       title: "Brand",
//       dataIndex: "brandId",
//       key: "brandId",
//       sorter: stringSorter("brandId"),
//       sortDirections: ["ascend", "descend"],
//       render: (brandId) => brandMap[brandId] || 'Unknown',
//     },
//     {
//       title: "Created At",
//       dataIndex: "createdAt",
//       key: "createdAt",
//       render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
//       sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
//         ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
//         : 0,
//       sortDirections: ["ascend", "descend"],
//     },
//     {
//       title: "Created By",
//       dataIndex: "createdByName",
//       key: "createdByName",
//       sorter: stringSorter("createdByName"),
//       sortDirections: ["ascend", "descend"],
//       render: renderCell,
//     },
//     {
//       title: "Updated At",
//       dataIndex: "updatedAt",
//       key: "updatedAt",
//       render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
//       sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
//         ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
//         : 0,
//       sortDirections: ["ascend", "descend"],
//     },
//     {
//       title: "Updated By",
//       dataIndex: "updatedByName",
//       key: "updatedByName",
//       sorter: stringSorter("updatedByName"),
//       sortDirections: ["ascend", "descend"],
//       render: renderCell,
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       key: "status",
//       render: (text, record) => (
//         <Switch
//           checked={text === 1}
//           onChange={() => handleChangeStatus(record._id, text)}
//         />
//       ),
//     },
//     {
//       title: "Action",
//       key: "action",
//       render: (_, record) => (
//         <Space size="middle">
//           <Button
//             type="link"
//             onClick={() => {
//               setSelectedRecord(record);
//               setIsModalVisible(true);
//             }}
//           >
//             <EditOutlined />
//           </Button         {/* <Popconfirm
//             title="Are you sure you want to delete this record?"
//             onConfirm={() => handleDelete(record._id)}
//           >
//             <Button type="link" danger>
//               Delete
//             </Button>
//           </Popconfirm> */}ton>
//  
//         </Space>
//       ),
//     },
//   ];
//   columns = enhanceColumnsWithFilters(columns);

//   return (
//     <div className="page-content">
//       <Breadcrumb style={{ marginBottom: "16px" }}>
//         <Breadcrumb.Item href="/">
//           <HomeOutlined />
//         </Breadcrumb.Item>
//         <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
//         <Breadcrumb.Item>Models</Breadcrumb.Item>
//       </Breadcrumb>

//       <Card>
//         <Space direction="vertical" size="middle" style={{ width: "100%" }}>
//           <Row justify="space-between" align="middle">
//             <Col>
//               <Title level={2} style={{ margin: 0 }}>
//                 Manage Models
//               </Title>
//             </Col>
//             <Col>
//               <Button
//                 type="primary"
//                 icon={<PlusOutlined />}
//                 onClick={() => {
//                   setSelectedRecord(null);
//                   setIsModalVisible(true);
//                 }}
//               >
//                 Add Model
//               </Button>
//             </Col>
//           </Row>
//           {isLoading ? (
//             <div className="d-flex justify-content-center">
//               <Spinner animation="border" variant="primary" />
//             </div>
//           ) : (
//             <DataTable
//               dataSource={model.map((item) => ({
//                 ...item,
//                 key: item._id,
//               }))}
//               columns={columns}
//             />
//           )}
//         </Space>
//       </Card>
//       <ModelsForm
//         visible={isModalVisible}
//         onCreate={handleCreate}
//         // onCancel={() => setIsModalVisible(false)}
//         onCancel={handleCancel}
//         categoryOptions={categoryOptions}
//         record={selectedRecord}
//       />
//     </div>
//   );
// };

// export default Model;