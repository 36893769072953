import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Spin,
  Switch,
} from "antd";
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ModelsForm from "./CityForm.js";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import { getStates } from "../../store/actions/state.js";
import { addCity, deleteCity, getCity, updateCity } from "../../store/actions/city.js";
import { getCountries } from "../../store/actions/country.js";
import { stringSorter } from "../../utils/functions.js";
import moment from "moment-timezone";
import CityFormUpdate from "./CityFormUpdate.js";

const { Title } = Typography;

const City = () => {
  document.title = "City";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setEditIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dispatch = useDispatch();
  const city = useSelector((state) => state.city.list);
  const stateList = useSelector((state) => state.state.list);
  const countryList = useSelector((state) => state.country.list);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          dispatch(getCountries()),
          dispatch(getStates()),
          dispatch(getCity()),
        ]);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const stateMap = stateList.reduce((acc, state) => {
    acc[state._id] = state.name;
    return acc;
  }, {});
  
  const countryMap = countryList.reduce((acc, country) => {
    acc[country._id] = country.name;   
    return acc;
  }, {});

  const handleCreate = async (values) => {
    try {
      // if (selectedRecord) {
      //   await dispatch(updateCity({ ...selectedRecord, ...values }));
      // } else {
      //   await dispatch(addCity(values));
      // }
      await dispatch(addCity(values));
      await dispatch(getCity());
    } catch (error) {
      console.error("Failed to create or update city:", error);
    }
    setIsModalVisible(false);
    setSelectedRecord(null); // Clear selected record after submission
  };

  const handleEdit = async (values) => {
    try {
      if (selectedRecord) {
        await dispatch(updateCity({ ...selectedRecord, ...values }));
      } 
      await dispatch(getCity());
    } catch (error) {
      console.error("Failed to  update city:", error);
    }
    setIsModalVisible(false);
    setSelectedRecord(null); // Clear selected record after submission
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteCity(id));
      await dispatch(getCity());
    } catch (error) {
      console.error("Failed to delete city:", error);
    }
  };

  const handleChangeStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    const updatedData = { _id: id, status: newStatus }; // Create an object for update
    await dispatch(updateCity(updatedData));
    await dispatch(getCity());
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };
  
  const handleEditModalClose = () => {
    setEditIsModalVisible(false);
    setSelectedRecord(null);
  };
  
  const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "State",
      dataIndex: "stateId",
      key: "stateId",
      sorter: (a, b) => a.stateId.localeCompare(b.stateId),
      sortDirections: ["ascend", "descend"],
      render: (stateId) => stateMap[stateId] || 'Unknown',
    },
    {
      title: "Country",
      dataIndex: "countryId",
      key: "countryId",
      sorter: (a, b) => a.countryId.localeCompare(b.countryId),
      sortDirections: ["ascend", "descend"],
      render: (countryId) => countryMap[countryId] || 'Unknown',
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={text === 1}
          onChange={() => handleChangeStatus(record._id, text)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setEditIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>City</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage City
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add City
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spin />
            </div>
          ) : (
            <DataTable
              dataSource={city.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <ModelsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={handleModalClose}
        record={selectedRecord}
      />
      <CityFormUpdate
        visible={isEditModalVisible}
        onCreate={handleEdit}
        onCancel={handleEditModalClose}
        record={selectedRecord}
      />
    </div>
  );
};

export default City;