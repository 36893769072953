import React from "react";

import { Row, Col } from "reactstrap";

import { LatestTransationData } from "../../CommonData/Data/index";
import moment from "moment-timezone";
import { Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductOrders,
  updateProductOrderStatus,
} from "../../store/actions/productorder";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const orderStatusMap = {
  0: "PENDING",
  1: "IN-PROCESS",
  2: "CANCEL",
  3: "COMPLETE",
};
const statusColorMap = {
  0: "warning", // yellow
  1: "info", // blue
  2: "danger", // red
  3: "success", // green
};

const statusOptions = Object.keys(orderStatusMap).map((key) => ({
  value: key,
  label: orderStatusMap[key],
}));

const LatestTransation = ({ order }) => {
  const limitedOrders = order.slice(0, 10);
  const dispatch = useDispatch();
  const handleStatusChange = async (value, record) => {
    try {
      Modal.confirm({
        title: 'Are you sure you want to change the order status?',
        content: `Changing status to ${orderStatusMap[value]}.`,
        onOk: async () => {
          try {
            await dispatch(updateProductOrderStatus(record._id, parseInt(value)));
            await dispatch(getProductOrders());
          } catch (error) {
            console.error('Error updating status:', error);
          }
        },
        onCancel() {
        
        },
      // await dispatch(updateProductOrderStatus(record._id, parseInt(value)));
      // await dispatch(getProductOrders());
      })
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Latest Orders</h4>

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      {/* <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th> */}
                      {/* <th scope="col" style={{ width: "60px" }}></th> */}
                      <th scope="col">Client Name</th>
                      <th scope="col">Order Items</th>
                      <th scope="col">Date</th>
                      <th scope="col">Price</th>
                      <th scope="col">Advance Amount</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Coupon</th>
                      <th scope="col">Order Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {limitedOrders.map((item, key) => (
                      <tr key={key}>
                        {/* <td>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id={item._id}
                                                    />
                                                    <label className="form-check-label" htmlFor={item._id}></label>
                                                </div>
                                            </td> */}
                        {/* <td>
                                                {item.imageUrl ? <img src={item.src} alt="user" className="avatar-xs rounded-circle" /> : <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                        {item.createdByName.charAt(0)}
                                                    </span>
                                                </div>}
                                            </td> */}
                        <td>
                          {/* <p className="mb-1 font-size-12">{item._id}</p> */}
                          <h5 className="font-size-15 mb-0">
                            {item.createdByName}
                          </h5>
                        </td>
                        <td>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {item.items.map((it, index) => (
                              <div key={index} style={{ marginRight: "10px" }}>
                                <div>{it.name}</div>
                                <hr />
                              </div>
                            ))}
                          </div>
                        </td>
                        <td>
                          {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>₹ {item.basicAmt}</td>
                        <td>{item.advAmt}</td>

                        <td>₹ {item.basicAmt - item.advAmt}</td>
                        <td>
                          {item.couponAmt}
                          {/* <i className={"mdi mdi-checkbox-blank-circle me-1 text-" + item.color}></i> */}
                        </td>
                        <td>
                          <span
                          // className={`badge bg-${statusColorMap[item.orderStatus]}`}
                          >
                            {/* {orderStatusMap[item.orderStatus]} */}
                            <Select
                              value={item.orderStatus.toString()}
                              style={{ width: 120 }}
                              onChange={(value) =>
                                handleStatusChange(value, item)
                              }
                            >
                              {statusOptions.map((option) => (
                                <Select.Option
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </span>
                        </td>
                        <td><Link to={"/prodorders"}><EyeOutlined /></Link></td>
                        {/* <td>
                                                <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                <button type="button" className="btn btn-outline-danger btn-sm me-1">Cancel</button>
                                            </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">

                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">ID & Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {LatestTransationData.map((item, key) => (<tr key={key}>
                                            <td>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id={item.id}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.id}></label>
                                                </div>
                                            </td>
                                            <td>
                                                {item.src ? <img src={item.src} alt="user" className="avatar-xs rounded-circle" /> : <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                        {item.clientName.charAt(0)}
                                                    </span>
                                                </div>}
                                            </td>
                                            <td>
                                                <p className="mb-1 font-size-12">{item.clientId}</p>
                                                <h5 className="font-size-15 mb-0">{item.clientName}</h5>
                                            </td>
                                            <td>{item.date}</td>
                                            <td>$ {item.price}</td>
                                            <td>{item.quantity}</td>

                                            <td>
                                                $ {item.quantity * item.price}
                                            </td>
                                            <td>
                                                <i className={"mdi mdi-checkbox-blank-circle me-1 text-" + item.color}></i> {item.status}
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                <button type="button" className="btn btn-outline-danger btn-sm me-1">Cancel</button>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LatestTransation;
