import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
} from "antd";
import { addFaq, deleteFaqs, getFaqs, updateFaqs } from "../../store/actions/faq.js"
import {
  HomeOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import FaqForm from "./FaqForm.js";
import moment from "moment-timezone";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";

const { Title } = Typography;

const FAQ = () => {
  document.title = "FAQs List";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dispatch = useDispatch();
  const faq = useSelector((state) => state.faq.list);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getFaqs());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);


  const handleCreate = async (values) => {
    const formData = new FormData();
  
    Object.keys(values).forEach(key => {
      if (values[key] !== undefined && values[key] !== null) {
        formData.append(key, values[key]);
      }
    });
  
    try {
      const formData = new FormData();
      if (selectedRecord) {
        formData.append('id', selectedRecord._id);      
        await dispatch(updateFaqs({ ...selectedRecord, ...values }));
      // if (selectedRecord) {
        
        // formData.append('id', selectedRecord._id);
        // console.log("formData :", formData);
        await dispatch(updateFaqs(formData));
        await dispatch(getFaqs());
      } else {
        await dispatch(addFaq(formData));
        await dispatch(getFaqs());
      }
      setIsModalVisible(false);
      return { success: true, message: 'Coupon operation completed successfully.' };
    } catch (error) {
      console.error('Error in coupon operation:', error);
      return { success: false, message: 'An error occurred during the coupon operation.' };
    }
  };

  const handleDelete = async (id) => {
    await dispatch(deleteFaqs(id));
    await dispatch(getFaqs());
  };


  const renderCell = (text) => (text ? text : 'N/A');

  let columns = [
    {
      title: "Question",
      dataIndex: "que",
      key: "que",
      sorter: (a, b) => a.que.localeCompare(b.que),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Answer",
      dataIndex: "ans",
      key: "ans",
      sorter: (a, b) => a.ans.localeCompare(b.ans),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      sorter: (a, b) => a.url.localeCompare(b.url),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>FAQs</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage FAQs
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add FAQ
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={faq.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <FaqForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
    </div>
  );
};

export default FAQ;
