import Swal from "sweetalert2";
import axios from "../../axios";
import {
  // ADD_TICKET,
  DELETE_TICKET,
  GET_ACTIVE_TICKET,
  GET_TICKET,
  UPDATE_TICKET,
  UPDATE_TICKET_STATUS
} from "../types";
import { setAlert } from "./alert";

// export const addTicket = (formData) => async (dispatch) => {
//   try {
//     const res = await axios.post("ticket-reason/add", formData);
//     if (res.data.code === 1) {
//       dispatch({ type: ADD_TICKET, payload: res.data.data });
//       Swal.fire({
//         title: "Success!",
//         text: res.data.message,
//         icon: "success",
//       });
//       dispatch(setAlert(res.data.message, "success"));
//     } else {
//       dispatch(setAlert(res.data.message, "warning"));
//     }
//   } catch (err) {
//     dispatch(setAlert(err.message, "danger"));
//     const errorMessage =
//     err.response?.data?.message || "An unexpected error occurred";
//     Swal.fire({
//       title: "Error!",
//       text: errorMessage,
//       icon: "error",
//     });
//   }
// };

export const getTicket = () => async (dispatch) => {
  try {
    const res = await axios.get("ticket/get");
    console.log("ticket :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_TICKET, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getActiveTicket = () => async (dispatch) => {
  try {
    const res = await axios.get("ticket/get?status=1");
    if (res.data.code === 1) {
      dispatch({ type: GET_ACTIVE_TICKET, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateTicket = (formData) => async (dispatch) => {
  try {
    const updatedFormData = {
      id: formData._id,
      ...(formData.ticketStatus !== undefined && { status: formData.ticketStatus }),
      ...(formData.status !== undefined && { status: formData.status }),
    };
    console.log("updatedFormData :", updatedFormData);

    const res = await axios.post("ticket/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_TICKET, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: "warning!",
        text: res.data.message,
        icon: "warning",
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateTicketOrderStatus = (record, newStatus) => async (dispatch) => {
  try {
    // Check if the record has an orderId before making the API call
    if (record.orderId && record.orderId._id) {
      const res = await axios.post("order/update", {
        id: record.orderId._id, // Use the _id of the orderId
        ticketStatus: newStatus,
        status: record.status,
        // ticketId: record._id // Include the ticket ID for reference
      });

      if (res.data.code === 1) {
        // Dispatch success actions
        dispatch({ 
          type: UPDATE_TICKET_STATUS, 
          payload: {
            ...res.data.data,
            _id: record._id, // Ensure the original ticket ID is preserved
            ticketStatus: newStatus 
          }
        });

        // Sweet Alert for success
        Swal.fire({
          title: 'Success!',
          text: res.data.message || 'Ticket status updated successfully',
          icon: 'success',
        });

        // Dispatch alert
        dispatch(setAlert(res.data.message, 'success'));
      } else {
        // Handle specific error scenarios
        dispatch(setAlert(res.data.message || 'Failed to update ticket status', 'warning'));
      }
    } else {
      // If no orderId, update ticket status directly
      const res = await axios.post("ticket/update", {
        id: record._id,
        ticketStatus: newStatus
      });

      if (res.data.code === 1) {
        dispatch({ 
          type: UPDATE_TICKET_STATUS, 
          payload: {
            ...res.data.data,
            _id: record._id,
            ticketStatus: newStatus 
          }
        });

        Swal.fire({
          title: 'Success!',
          text: res.data.message || 'Ticket status updated successfully',
          icon: 'success',
        });

        dispatch(setAlert(res.data.message, 'success'));
      } else {
        dispatch(setAlert(res.data.message || 'Failed to update ticket status', 'warning'));
      }
    }
  } catch (err) {
    console.error('Error updating ticket/order status:', err);
    dispatch(setAlert(err.message, 'danger'));
  }
};

export const deleteTicket = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`ticket/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_TICKET, payload: { id } });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
