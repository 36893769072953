import { ADD_CUST, ADD_CUSTOMERS, DELETE_CUSTOMERS, GET_CUSTOMERS, GET_DASH_CUSTOMERS, UPDATE_CUSTOMER } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CUSTOMERS:
            return { ...state, list: payload };
        
        case GET_DASH_CUSTOMERS:
            return { ...state, list: payload };

        case UPDATE_CUSTOMER:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case ADD_CUST:
                return {
                    ...state,
                    list: [payload, ...state.list],
                };

        case ADD_CUSTOMERS:
                return {
                    ...state,
                    list: [payload, ...state.list],
                };

        case DELETE_CUSTOMERS:
            return {
                ...state,
                list: state.list.filter((item) => item._id !== payload._id),
            };

        default:
            return state;
    }
};

export default reducer;
