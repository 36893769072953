import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../store/actions/customers";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const { Title } = Typography;

const CustomerPanel = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customers = useSelector((state) => state.customers.list);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch all customers in a single request
        await dispatch(getCustomers());
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const customerCounts = useMemo(() => {
    if (!customers.length) return {};

    const now = new Date();
    const today = now.toISOString().split('T')[0];
    const last7Days = new Date(now.setDate(now.getDate() - 7)).toISOString().split('T')[0];
    // Calculate the fromDate and toDate for last month
    const nowforlastMonth = moment();
    const fromDate = nowforlastMonth.clone().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const toDate = nowforlastMonth.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    
    return {
      active: customers.filter(c => c.status === 1).length,
      inactive: customers.filter(c => c.status === 0).length,
      today: customers.filter(c => c.status === 1 && c.createdAt.split('T')[0] === today).length,
      last7Days: customers.filter(c => c.status === 1 && c.createdAt.split('T')[0] >= last7Days).length,
      lastMonth: customers.filter(c => c.status === 1 && c.createdAt.split('T')[0] >= fromDate && c.createdAt.split('T')[0] <= toDate).length
    };
  }, [customers]);

  const handleCardClick = (type) => {
    if (type === "active") {
      navigate('/users/active');  // Navigate to the /users/active route
    }
    if (type === "inactive") {
      navigate('/users/inactive');  // Navigate to the /users/inactive route
    }
    if (type === "today") {
      navigate('/users/today'); 
    }
    if (type === "last7Days") {
      navigate('/users/last7Days'); 
    }
    if (type === "lastMonth") {
      navigate('/users/lastMonth'); 
    }
  };

  const renderCustomerCard = (title, count, type) => (
    <Col xl={4} sm={12} >
      <Card>
        <CardBody onClick={() => handleCardClick(type)} style={{ cursor: 'pointer' }}>
          <div className="d-flex text-muted">
            <div className="flex-shrink-0 me-3 align-self-center">
              <div className="avatar-sm">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="ri-group-line"></i>
                </div>
              </div>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="mb-1">{title}</p>
              <h5 className="mb-3">{isLoading ? 'Loading...' : count}</h5>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );

  return (
    <React.Fragment>
      <Row>
        <Title level={5}>Customers</Title>
        {renderCustomerCard("Active Customers", customerCounts.active, "active")}
        {renderCustomerCard("Inactive Customers", customerCounts.inactive, "inactive")}
        {renderCustomerCard("Today's Customers", customerCounts.today, "today")}
        {renderCustomerCard("Last 7 Days Customers", customerCounts.last7Days, "last7Days")}
        {renderCustomerCard("Last Month Customers", customerCounts.lastMonth, "lastMonth")}
      </Row>
    </React.Fragment>
  );
};

export default CustomerPanel;