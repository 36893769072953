import { GET_TOP_BRAND } from "../types";

const initialState = { topBrand: []  };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOP_BRAND:
      return { ...state, topBrand: payload };
    default:
      return state;
  }
};

export default reducer;