import React, { useEffect } from 'react';
import { Form, Input, Modal } from 'antd';

const FaqForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        que: record.que,
        ans: record.ans,
        url: record.url
      });
    }
  }, [record, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onCreate(values);
      form.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update FAQ" : "Add FAQ"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="faq_form"
      >
        <Form.Item
          name="que"
          label="Question"
          rules={[{ required: true, message: 'Please enter the question!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="ans"
          label="Answer"
          rules={[{ required: true, message: 'Please enter the answer!' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="url"
          label="URL"
          rules={[{ required: true, message: 'Please enter the URL!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FaqForm;
