import { ADD_BRAND, DELETE_BRAND, GET_BRAND, GET_BRANDS, UPDATE_BRAND } from "../types";

const initialState = {list: [], single: null, cities: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_BRANDS:
            return {...state, list: payload};

        case UPDATE_BRAND:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        case ADD_BRAND:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case DELETE_BRAND:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== payload.id),
            };

        default:
            return state;
    }
};

export default reducer;
