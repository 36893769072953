import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customFilterDropdown, stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Switch,
  Modal,
  Select
} from "antd";
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import moment from "moment-timezone";
import { deleteTicket, getTicket, updateTicket, updateTicketOrderStatus } from "../../store/actions/ticket.js";



const { Title } = Typography;

const ticketStatusMap = {
  0: 'PENDING',
  1: 'IN-PROCESS',
  2: 'CANCEL',
  3: 'COMPLETE',
};

const statusOptions = Object.keys(ticketStatusMap).map((key) => (
  <Select.Option key={key} value={key}>
    {ticketStatusMap[key]}
  </Select.Option>
));



const TicketReason = () => {
  document.title = "Ticket Reason";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [mediaContent, setMediaContent] = useState([]); // This will hold the media data
  const [photoContent, setPhotoContent] = useState([]);  // Store the photos to display
  const [videoContent, setVideoContent] = useState([]);

  // Function to show the modal with media content
  const showMediaModal = (media) => {
     // Separate media into photos and videos
      const photos = media.filter(item => item.type === 'PHOTO');
      const videos = media.filter(item => item.type === 'VIDEO');

      setPhotoContent(photos);  // Set the photos to display
      setVideoContent(videos);  // Set the videos to display
      setIsModalVisible(true);  // Open the modal
    // setMediaContent(media); // Set the media data to be displayed
    setIsModalVisible(true); // Open the modal
  };

  // Function to close the modal
  const handleCancel = () => {
    setIsModalVisible(false); // Close the modal
  };


  const dispatch = useDispatch();
  const ticket = useSelector((state) => state.ticket.list);


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getTicket());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);


  const handleDelete = async (id) => {
    await dispatch(deleteTicket(id));
    await dispatch(getTicket());
  };

  const handleChangeStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    const updatedData = { _id: id, status: newStatus }; // Create an object for update
    await dispatch(updateTicket(updatedData));
    await dispatch(getTicket());
  };

  const handleStatusChange = async (value, record) => {
    try {
      Modal.confirm({
        title: 'Are you sure you want to change the ticket status?',
        content: `Changing status to ${ticketStatusMap[value]}.`,
        onOk: async () => {
          try {
            await dispatch(updateTicketOrderStatus(record, parseInt(value)));
            await dispatch(getTicket());
          } catch (error) {
            console.error('Error updating status:', error);
          }
        },
        onCancel() {
          // Optional: Add cancel logic if needed
        },
      });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // const handleStatusChange = async (value, record) => {
  //   try {
  //     Modal.confirm({
  //       title: 'Are you sure you want to change the ticket status?',
  //       content: `Changing status to ${ticketStatusMap[value]}.`,
  //       onOk: async () => {
  //         try {
  //           await dispatch(updateTicketOrderStatus(record._id, parseInt(value)));
  //           await dispatch(getTicket());
  //         } catch (error) {
  //           console.error('Error updating status:', error);
  //         }
  //       },
  //       onCancel() {
        
  //       },
  //     });
  //     // await dispatch(updateProductOrderStatus(record._id, parseInt(value)));
  //     // await dispatch(getProductOrders());
  //   } catch (error) {
  //     console.error('Error updating status:', error);
  //   }
  // };

  const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Ticket No.",
      dataIndex: "ticketNo",
      key: "ticketNo",
      sorter: stringSorter("ticketNo"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text, record) => record.reasonId ? record.reasonId.name : 'N/A', // Showing reason name from reasonId
      sorter: stringSorter("reason"),
      sortDirections: ["ascend", "descend"],
    },  
    {
      title: "Ticket Status",
      dataIndex: "ticketStatus",
      key: "ticketStatus",
      render: (ticketStatus, record) => (
        <Select
          value={ticketStatus !== undefined ? ticketStatus.toString() : undefined}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
        >
          {statusOptions}
        </Select>
      ),
      sorter: (a, b) => a.orderStatus - b.orderStatus,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Media",
      key: "media",
      render: (_, record) => (
        <div>
          <Button onClick={() => showMediaModal(record.media)}>Show Media</Button> {/* Button to open modal */}
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={text === 1}
          onChange={() => handleChangeStatus(record._id, text)}
        />
      ),
    }
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         type="link"
    //         onClick={() => {
    //           setSelectedRecord(record);
    //           setIsModalVisible(true);
    //         }}
    //       >
    //         <EditOutlined />
    //       </Button> 
    //      <Popconfirm
    //         title="Are you sure you want to delete this record?"
    //         onConfirm={() => handleDelete(record._id)}
    //       >
    //         <Button type="link" danger>
    //           Delete
    //         </Button>
    //       </Popconfirm> 
    //     </Space>
    //   ),
    // },
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Ticket</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Ticket
              </Title>
            </Col>
            <Col>
              {/* <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Ticket Reason
              </Button> */}
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={ticket.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
           <Modal
          title="Media Preview"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={800}
        >
          <div>
            {/* Display Photos */}
            {photoContent.length > 0 && (
              <div>
                <h3>Photos</h3>
                {photoContent.map((mediaItem, index) => (
                  <div key={index} style={{ marginBottom: 20 }}>
                    <img
                      src={mediaItem.url}
                      alt="Ticket Media"
                      style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* Display Videos */}
            {videoContent.length > 0 && (
              <div>
                <h3>Videos</h3>
                {videoContent.map((mediaItem, index) => (
                  <div key={index} style={{ marginBottom: 20 }}>
                    <video width="100%" controls>
                      <source src={mediaItem.url} type="video/mp4" />
                    </video>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
        </Space>
      </Card>
    </div>
  );
};

export default TicketReason;