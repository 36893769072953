import { Modal } from "antd";
import moment from "moment-timezone";

const OrderItemsDetail = ({ visible, onClose, order }) => {
  return (
    <Modal
      title="Order Details"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
      zIndex={1000000}
    >
      {order ? (
        <div>
          <h3>Order ID: {order._id}</h3>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Created At:</strong>
                </td>
                <td>{moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
              </tr>
              <tr>
                <td>
                  <strong>Created By:</strong>
                </td>
                <td>{order.createdByName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Basic Amount:</strong>
                </td>
                <td>{order.basicAmt}</td>
              </tr>
              <tr>
                <td>
                  <strong>Coupon Amount:</strong>
                </td>
                <td>-{order.couponAmt}</td>
              </tr>
              <tr>
                <td>
                  <strong>Advance Amount:</strong>
                </td>
                <td>{order.advAmt}</td>
              </tr>
              <tr>
                <td>
                  <strong>Total Amount:</strong>
                </td>
                <td>{order.totalAmt}</td>
              </tr>
              <tr>
                <td>
                  <strong>Order Status:</strong>
                </td>
                <td>
                  {order.orderStatus === 0
                    ? "Pending"
                    : order.orderStatus === 1
                    ? "Completed"
                    : "Cancelled"}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Note:</strong>
                </td>
                <td>{order.note}</td>
              </tr>
            </tbody>
          </table>

          <h4>Items:</h4>
             <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Model</th>
                      <th scope="col">Series</th>
                      <th scope="col">Color</th>
                      <th scope="col">Storage</th>
                      <th scope="col">RAM</th>
                      <th scope="col">IMEI</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map((item, key) => (
                      <tr key={key}>
                       
                        <td>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div>{item.name}</div>
                          </div>
                        </td>
                        <td>{item.brandName}</td>
                        <td>{item.modelName}</td>
                        <td>{item.seriesName}</td>
                        <td>{item.color}</td>
                        <td>{item.storage}</td>
                        <td>{item.ram}</td>
                        <td>{item.imei}</td>
                        <td>₹ {item.cartPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
        </div>
      ) : (
        <p>No details available.</p>
      )}
    </Modal>
  );
};

export default OrderItemsDetail;
