import Swal from "sweetalert2";
import axios from "../../axios";
import {
  ADD_COUNTRY,
  DELETE_COUNTRY,
  GET_COUNTRYS,
  UPDATE_COUNTRY,
  GET_ACTIVE_COUNTRYS,
} from "../types";
import { setAlert } from "./alert";

export const addCountry = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("country/add", formData);
    if (res.data.code === 1) {
      dispatch({ type: ADD_COUNTRY, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    const errorMessage =
    err.response?.data?.message || "An unexpected error occurred";
    Swal.fire({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
};
export const getCountries = () => async (dispatch) => {
  try {
    const res = await axios.get("country/get");
    console.log("country :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_COUNTRYS, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getActiveCountries = () => async (dispatch) => {
  try {
    const res = await axios.get("country/get?status=1");
    if (res.data.code === 1) {
      dispatch({ type: GET_ACTIVE_COUNTRYS, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateCountry = (formData) => async (dispatch) => {
  try {
    const updatedFormData = {
      id: formData._id,
      ...(formData.name && { name: formData.name }),
      ...(formData.status !== undefined && { status: formData.status }),
      // ...(formData.countryId && { countryId: formData.countryId }),
    };
    console.log("updatedFormData :", updatedFormData);

    const res = await axios.post("country/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_COUNTRY, payload: res.data.data });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: "warning!",
        text: res.data.message,
        icon: "warning",
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteCountry = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`country/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_COUNTRY, payload: { id } });
      Swal.fire({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
