import React, { useEffect, useState } from "react";
import RadialChart from "./RadialChart";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTopBrand } from "../../store/actions/products";

const SocialSource = () => {
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const monthMapping = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const [selectedMonth, setSelectedMonth] = useState(
    monthMapping[currentMonthIndex].name
  );
  const [selectedMonthCount, setSelectedMonthCount] = useState(
    `${currentYear}-${currentMonthIndex + 1}`
  );
  const { topBrands, totalPriceTopBrands } = useSelector(
    (state) => state.topBrand.topBrand
  );

  useEffect(() => {
    const fetchTopBrands = async () => {
      console.log("Fetching data for:", selectedMonth);
      try {
        await dispatch(getTopBrand(selectedMonthCount));
      } catch (error) {
        console.error("Error fetching top brands:", error);
      }
    };

    fetchTopBrands();
  }, [dispatch, selectedMonth]);

  const handleMonthChange = (e) => {
    const selectedMonthName = e?.target?.value;
    setSelectedMonth(selectedMonthName);

    const selectedMonthNumber = monthMapping?.find(
      (month) => month.name === selectedMonthName
    )?.value;
    const formattedMonth = `${currentYear}-${selectedMonthNumber}`;
    setSelectedMonthCount(formattedMonth);
  };

  const shouldRenderChartAndBrands =
  topBrands?.length > 0 && totalPriceTopBrands > 0;

  const colorData = ["#099680", "#63affd", "#5664d2"];
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">Top 3 Brands</h5>
              </div>
              <div className="flex-shrink-0">
                <select
                  className="form-select form-select-sm mb-0 my-n1"
                  select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  {monthMapping?.map((item, key) => (
                    <option key={key} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {shouldRenderChartAndBrands && (
              <RadialChart
                topBrands={topBrands}
                totalPrice={totalPriceTopBrands}
              />
            )}
            <Row
              style={{
                justifyContent:
                  shouldRenderChartAndBrands === 0 ? "center" : "",
              }}
            >
              {topBrands?.length > 0 ? (
                topBrands?.map((item, key) => {
                  const colorIndex = key % colorData.length;
                  return (
                    <div key={key} className="col-4">
                      <div className="social-source text-center mt-3">
                        <div className="avatar-xs mx-auto mb-3">
                          <span
                            className="avatar-title rounded-circle font-size-18"
                            style={{ backgroundColor: colorData[colorIndex] }}
                          >
                            <a
                              className={item.icon + " text-white"}
                              href={item?.brandImage ? item?.brandImage : "#"}
                            />
                          </span>
                        </div>
                        <h5 className="font-size-15">
                          {item?.brandName ? item?.brandName : "Apple"}
                        </h5>
                        <p className="text-muted mb-0">
                        ₹ {item?.totalPrice ? item?.totalPrice : "0"}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span className="avatar-title rounded-circle font-size-18 bg-secondary">
                        <span className="text-white">N/A</span>{" "}
                      </span>
                    </div>
                    <h5 className="font-size-15">No Brands Available</h5>
                    <p className="text-muted mb-0">0 Price</p>
                  </div>
                </div>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SocialSource;
