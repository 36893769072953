import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/CustomProductOrder/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Image,
  Tag,
  Table,
  Select,
  Modal,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import ProductsForm from "./ProductOrderForm.js";
import { addProductOrder, deleteProductOrder, getProductOrders, updateProductOrder, updateProductOrderStatus } from "../../store/actions/productorder.js";
import moment from "moment-timezone";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import { stringSorter } from "../../utils/functions.js";
import OrderItemsDetail from "./OrderItemsDetail.js";

const { Title } = Typography;

const { Option } = Select;

const orderStatusMap = {
  0: 'PENDING',
  1: 'IN-PROCESS',
  2: 'CANCEL',
  3: 'COMPLETE',
};

const statusOptions = Object.keys(orderStatusMap).map((key) => (
  <Option key={key} value={key}>
    {orderStatusMap[key]}
  </Option>
));

const ProductOrder = () => {
  document.title = "Product Order List";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);


  const dispatch = useDispatch();
  const productorder = useSelector((state) => state.productorder.list);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getProductOrders());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);


  const handleCreate = async (values) => {
    const formData = new FormData();
  
    Object.keys(values).forEach(key => {
      if (values[key] !== undefined && values[key] !== null) {
        formData.append(key, values[key]);
      }
    });
  
    try {
      if (selectedRecord) {
        formData.append('id', selectedRecord._id);
        await dispatch(updateProductOrder(formData));
        await dispatch(getProductOrders());
      } else {
        await dispatch(addProductOrder(formData));
        await dispatch(getProductOrders());
      }
      setIsModalVisible(false);
      return { success: true, message: 'Coupon operation completed successfully.' };
    } catch (error) {
      console.error('Error in coupon operation:', error);
      return { success: false, message: 'An error occurred during the coupon operation.' };
    }
  };

  const handleStatusChange = async (value, record) => {
    try {
      Modal.confirm({
        title: 'Are you sure you want to change the order status?',
        content: `Changing status to ${orderStatusMap[value]}.`,
        onOk: async () => {
          try {
            await dispatch(updateProductOrderStatus(record._id, parseInt(value)));
            await dispatch(getProductOrders());
          } catch (error) {
            console.error('Error updating status:', error);
          }
        },
        onCancel() {
        
        },
      });
      // await dispatch(updateProductOrderStatus(record._id, parseInt(value)));
      // await dispatch(getProductOrders());
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleViewDetails = (record) => {
    setSelectedOrder(record);
    setIsDetailModalVisible(true);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteProductOrder(id));
    await dispatch(getProductOrders());
  };

  const renderCell = (text) => (text ? text : 'N/A');


  const expandedRowRender = (record) => {
    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Brand', dataIndex: 'brandName', key: 'brandName' },
      { title: 'Model', dataIndex: 'modelName', key: 'modelName' },
      { title: 'Series', dataIndex: 'seriesName', key: 'seriesName' },
      { title: 'Color', dataIndex: 'color', key: 'color' },
      { title: 'Storage', dataIndex: 'storage', key: 'storage' },
      { title: 'IMEI', dataIndex: 'imei', key: 'imei' },
      { title: 'Price', dataIndex: 'cartPrice', key: 'cartPrice' },
      { title: 'Quantity', dataIndex: 'qty', key: 'qty' },
    ];

    return <DataTable columns={columns} dataSource={record.items} pagination={false} />;
  };

  

  let columns = [
    {
      title: "Order ID",
      dataIndex: "_id",
      key: "_id",
      sorter: (a, b) => a._id.localeCompare(b._id),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Order Items",
      dataIndex: "items",
      key: "items",
      render: (items) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {items.map((item, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              <div>{item.name}</div>
              <hr />
            </div>
          ))}
        </div>
      ),
    }, 
    {
      title: "Product Price",
      dataIndex: "items",
      key: "itemPrice",
      render: (items) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {items.map((item, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              <div>{item.cartPrice}</div>
              <hr />
            </div>
          ))}
        </div>
      ),
      // render: (items) => items.map(item => item.cartPrice).join(', '),
    },
    {
      title: "Product Discount",
      dataIndex: "items",
      key: "disPer",
      render: (items) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {items.map((item, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              <div>{item.disPer ? item.disPer : 0}%</div>
              <hr />
            </div>
          ))}
        </div>
      ),
      // render: (items) => items.map(item => item.cartPrice).join(', '),
    },
    {
      title: "Basic Total Price (with Discount)",
      dataIndex: "basicAmt",
      key: "basicAmt",
      sorter: (a, b) => a.basicAmt - b.basicAmt,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Coupon Amount",
      dataIndex: "couponAmt",
      key: "couponAmt",
      sorter: (a, b) => a.couponAmt - b.couponAmt,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Total Price",
      dataIndex: "totalAmt",
      key: "totalAmt",
      sorter: (a, b) => a.totalAmt - b.totalAmt,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Advance Amount",
      dataIndex: "advAmt",
      key: "advAmt",
      sorter: (a, b) => a.advAmt - b.advAmt,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Proof Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => <Image src={imageUrl} alt="Order" style={{ width: 100, height: 100 }} />,
    },
    {
      title: "Payable Amount",
      key: "payableAmt",
      render: (text, record) => record.totalAmt - record.advAmt,
      sorter: (a, b) => (a.totalAmt - a.advAmt) - (b.totalAmt - b.advAmt),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      sorter: (a, b) => a.note.localeCompare(b.note),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (orderStatus, record) => (
        <Select
          value={orderStatus.toString()}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
        >
          {statusOptions}
        </Select>
      ),
      sorter: (a, b) => a.orderStatus - b.orderStatus,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        record && record._id ? (
          <Button
            type="link"
            // icon={<EyeOutlined />}
            onClick={() => handleViewDetails(record)}
          >
            <EyeOutlined />
          </Button>
        ) : 'N/A'
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         type="link"
    //         onClick={() => {
    //           setSelectedRecord(record);
    //           setIsModalVisible(true);
    //         }}
    //       >
    //         <EditOutlined />
    //       </Button>
    //       <Popconfirm
    //         title="Are you sure you want to delete this record?"
    //         onConfirm={() => handleDelete(record._id)}
    //       >
    //         <Button type="link" danger>
    //           Delete
    //         </Button>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];
  
  
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Product Order</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Product Order
              </Title>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={productorder.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
              expandable={{
                expandedRowRender,
                rowExpandable: record => record.items && record.items.length > 0,
              }}
            />
          )}
        </Space>
      </Card>
      <ProductsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
      <OrderItemsDetail
        visible={isDetailModalVisible}
        onClose={() => setIsDetailModalVisible(false)}
        order={selectedOrder}
      />
    </div>
  );
};

export default ProductOrder;
