import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    customFilterDropdown,
  stringSorter,
} from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
} from "antd";
import {
  HomeOutlined,
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { deleteCustomers, getStaff, updateCustomers, addCustomer, addStaff, updateStaffStatus } from "../../store/actions/customers.js";
import {SearchOutlined} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import StaffForm from './StaffForm.js';
import moment from "moment-timezone";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import CustomToggleSwitch from "../../components/CustomToggleSwitch.js";
import StaffFormEdit from "./StaffFormEdit.js";

// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
const { Title } = Typography;


const Staff = () => {
  document.title = "Staff List";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const dispatch = useDispatch();
  const customersOrders = useSelector((state) => state.customers.list);


  useEffect(() => {
    const fetchCustomerss = async () => {
      try {
        await dispatch(getStaff());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchCustomerss();
  }, [dispatch]);

  const handleCreate = async (values) => {
      await dispatch(addStaff(values));
      await dispatch(getStaff())
    setIsModalVisible(false);
  };

  const handleEdit = async (values) => {
    if (selectedRecord) {      
      await dispatch(updateCustomers({ ...selectedRecord, ...values }));
      await dispatch(getStaff());
    } 
    setIsEditModalVisible(false);
  };

  // const handleDelete = async (key) => {
  //   await dispatch(deleteCustomers(key));
  // };

  useEffect(() => {
    const fetchAmazonOrders = async () => {
      try {
        await dispatch(getStaff());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchAmazonOrders();
  }, [dispatch]);

  useEffect(() => {
    console.log(customersOrders);
  }, [customersOrders]);

  const handleChangeStatus = async (id, status) => {
    const formData = new FormData();
    formData.append('_id', id);
    formData.append('status', status);
    await dispatch(updateStaffStatus(id, status));
    await dispatch(getStaff());
  };


const roleMapping = {
  1: 'Admin',
  2: 'Staff',
  3: 'Customer'
};

const roleFilters = Object.keys(roleMapping).map(role => ({
  text: roleMapping[role],
  value: role,
}));


const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: stringSorter("mobile"),
      sortDirections: ["ascend", "descend"],
      render: (text) => (
        // <span className="badge rounded-pill bg-info text-white" style={{ height: '20px', lineHeight: '15px' }} >
          <span>{text || 'N/A'}</span>
        // </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filters: [
        ...[...new Set((customersOrders || []).map(order => order.email))]
            .filter(email => email !== null && email !== undefined)
            .map(email => ({
                text: email || 'N/A',
                value: email,
            })),
    ],
    onFilter: (value, record) => record.email === value,
    sorter: stringSorter("email"),
    sortDirections: ["ascend", "descend"],
    render: (text, record) => (
        <span className="" style={{ height: '20px', lineHeight: '15px' }} >{record.email || 'N/A'}</span>
    ),
    },
    {
      title: "city",
      dataIndex: "city",
      key: "city",
      filterDropdown: customFilterDropdown,
      onFilter: (value, record) => record.city.includes(value),
      sorter: stringSorter("city"),
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
          <span style={{ height: '20px', lineHeight: '15px' }} >{record.city}</span>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      filters: [
        ...[...new Set((customersOrders || []).map(order => order.state))]
            .filter(state => state !== null && state !== undefined)
            .map(state => ({
                text: state || 'N/A',
                value: state,
            })),
    ],
    onFilter: (value, record) => record.shipToState === value,
    sorter: stringSorter("shipToState"),
    sortDirections: ["ascend", "descend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      filters: [
        ...[...new Set((customersOrders || []).map(order => order.country))]
            .filter(country => country !== null && country !== undefined)
            .map(country => ({
                text: country || 'N/A',
                value: country,
            })),
    ],
    onFilter: (value, record) => record.country === value,
    sorter: stringSorter("country"),
    sortDirections: ["ascend", "descend"],
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      filters: roleFilters,
      onFilter: (value, record) => record.role === value,
      sorter: (a, b) => {
        const roleA = a.role;
        const roleB = b.role;
        return roleA - roleB; // Numeric sorting
      },
      sortDirections: ["ascend", "descend"],
      render: (text) => roleMapping[text] || 'N/A', 
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <CustomToggleSwitch
          record={record}
          onChangeStatus={handleChangeStatus}
        />
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsEditModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record.key)}
          >
            <Button type="link" danger>Delete</Button>
          </Popconfirm> */}
        </Space>
      ),
    }
  ];
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Staff</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Staff
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Staff
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={customersOrders.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <StaffForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
      <StaffFormEdit
        visible={isEditModalVisible}
        onCreate={handleEdit}
        onCancel={() => setIsEditModalVisible(false)}
        record={selectedRecord}
      />
    </div>
  );
};

export default Staff;
