import axios from "../../axios";
import {login, logout} from "../../utils/auth";
import {AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT} from "../types";
import Swal from 'sweetalert2';

export const authLogin = (data, history) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Authorization': 'essential' 
            }
        };
        const res = await axios.post('user/login', data, config);

        if (res.data.code === 1) {            
            let user = {access_token: res.data.data.token, refresh_token: res.data.data.token};
            login(user);
            dispatch({type: AUTH_LOGIN, payload: user});
            history("/dashboard")
            Swal.fire({
                title: 'Success!',
                text: 'Login successful!',
                icon: 'success',
            });

        } else {
            // dispatch({type: AUTH_ERROR, payload: res.data.message});
            if (res.data.code === 0){
                console.log("res.data.message :", res.data.data.message);
                
                dispatch({type: AUTH_ERROR, payload: res.data.data.message});
                Swal.fire({
                    title: 'Login Failed',
                    text: res.data.data.message,
                    icon: 'warning',
                });
            }
            // dispatch(setAlert(res.data.message, 'warning'));
        }
    } catch (err) {
        dispatch({type: AUTH_ERROR, payload: err.message});
        
        Swal.fire({
            title: 'Error',
            text: err.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK'
        });
        // dispatch(setAlert(err.message, 'danger'));
    }
};


// User Logout
export const authLogout = () => async (dispatch) => {
    try {
        logout();
        dispatch({type: AUTH_LOGOUT, payload: ''});
        // dispatch(setAlert('Logout done', 'success'));
    } catch (err) {
        dispatch({type: AUTH_ERROR, payload: err.message});
        // dispatch(setAlert(err.message, 'danger'));
    }
};
