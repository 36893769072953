import { GET_SETTING, UPDATE_SETTING } from "../types";

const initialState = {list: [], single: null, cities: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_SETTING:
            return {...state, list: payload};

        case UPDATE_SETTING:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload._id ? payload : item
                ),
            };

        default:
            return state;
    }
};

export default reducer;
